import React from 'react'
import PropTypes from 'prop-types'

const PortfolioBreachEventsIconSVG = ({ width, height, className }) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 91 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 60C18 59.4477 18.4477 59 19 59L59 59C59.5523 59 60 59.4477 60 60C60 60.5523 59.5523 61 59 61L19 61C18.4477 61 18 60.5523 18 60Z"
      fill="#D5DDE4"
    />
    <path d="M39 55.5V50" stroke="#D5DDE4" strokeWidth="2" />
    <rect
      x="34"
      y="57"
      width="10"
      height="6"
      rx="2"
      fill="white"
      stroke="#D5DDE4"
      strokeWidth="2"
    />
    <rect
      x="1"
      y="37"
      width="76"
      height="13"
      rx="2"
      fill="white"
      stroke="#0E2435"
      strokeWidth="2"
    />
    <rect
      x="1"
      y="19"
      width="76"
      height="13"
      rx="2"
      fill="white"
      stroke="#0E2435"
      strokeWidth="2"
    />
    <circle cx="64.5" cy="25.5" r="3.5" fill="#FB9B0B" />
    <circle cx="65.5" cy="43.5" r="3.5" fill="#B30015" />
    <rect
      x="1"
      y="1"
      width="76"
      height="13"
      rx="2"
      fill="white"
      stroke="#0E2435"
      strokeWidth="2"
    />
    <circle cx="49.5" cy="25.5" r="3.5" fill="#FB9B0B" />
    <circle cx="49.5" cy="43.5" r="3.5" fill="#B30015" />
    <circle cx="77" cy="25" r="14" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M89 25C89 31.6274 83.6274 37 77 37C70.3726 37 65 31.6274 65 25C65 18.3726 70.3726 13 77 13C83.6274 13 89 18.3726 89 25ZM77 35C82.5228 35 87 30.5228 87 25C87 19.4772 82.5228 15 77 15C71.4772 15 67 19.4772 67 25C67 30.5228 71.4772 35 77 35Z"
      fill="#B30015"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M77 19C77.5523 19 78 19.4477 78 20V26C78 26.5523 77.5523 27 77 27C76.4477 27 76 26.5523 76 26V20C76 19.4477 76.4477 19 77 19Z"
      fill="#B30015"
    />
    <rect x="76" y="28" width="2" height="2" fill="#B30015" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 11C4.44772 11 4 10.9254 4 10.8333L4 4.16667C4 4.07462 4.44771 4 5 4C5.55228 4 6 4.07462 6 4.16667L6 10.8333C6 10.9254 5.55228 11 5 11Z"
      fill="#F2C94C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 29C4.44772 29 4 28.9254 4 28.8333L4 22.1667C4 22.0746 4.44771 22 5 22C5.55228 22 6 22.0746 6 22.1667L6 28.8333C6 28.9254 5.55228 29 5 29Z"
      fill="#FB9B0B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 47C4.44772 47 4 46.9254 4 46.8333L4 40.1667C4 40.0746 4.44771 40 5 40C5.55228 40 6 40.0746 6 40.1667L6 46.8333C6 46.9254 5.55228 47 5 47Z"
      fill="#B30015"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 11C8.44772 11 8 10.9254 8 10.8333L8 4.16667C8 4.07462 8.44771 4 9 4C9.55228 4 10 4.07462 10 4.16667L10 10.8333C10 10.9254 9.55228 11 9 11Z"
      fill="#F2C94C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 29C8.44772 29 8 28.9254 8 28.8333L8 22.1667C8 22.0746 8.44771 22 9 22C9.55228 22 10 22.0746 10 22.1667L10 28.8333C10 28.9254 9.55228 29 9 29Z"
      fill="#FB9B0B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 47C8.44772 47 8 46.9254 8 46.8333L8 40.1667C8 40.0746 8.44771 40 9 40C9.55228 40 10 40.0746 10 40.1667L10 46.8333C10 46.9254 9.55228 47 9 47Z"
      fill="#B30015"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 11C12.4477 11 12 10.9254 12 10.8333L12 4.16667C12 4.07462 12.4477 4 13 4C13.5523 4 14 4.07462 14 4.16667L14 10.8333C14 10.9254 13.5523 11 13 11Z"
      fill="#F2C94C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 29C12.4477 29 12 28.9254 12 28.8333L12 22.1667C12 22.0746 12.4477 22 13 22C13.5523 22 14 22.0746 14 22.1667L14 28.8333C14 28.9254 13.5523 29 13 29Z"
      fill="#FB9B0B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 47C12.4477 47 12 46.9254 12 46.8333L12 40.1667C12 40.0746 12.4477 40 13 40C13.5523 40 14 40.0746 14 40.1667L14 46.8333C14 46.9254 13.5523 47 13 47Z"
      fill="#B30015"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 11C16.4477 11 16 10.9254 16 10.8333L16 4.16667C16 4.07462 16.4477 4 17 4C17.5523 4 18 4.07462 18 4.16667L18 10.8333C18 10.9254 17.5523 11 17 11Z"
      fill="#F2C94C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 29C16.4477 29 16 28.9254 16 28.8333L16 22.1667C16 22.0746 16.4477 22 17 22C17.5523 22 18 22.0746 18 22.1667L18 28.8333C18 28.9254 17.5523 29 17 29Z"
      fill="#FB9B0B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 47C16.4477 47 16 46.9254 16 46.8333L16 40.1667C16 40.0746 16.4477 40 17 40C17.5523 40 18 40.0746 18 40.1667L18 46.8333C18 46.9254 17.5523 47 17 47Z"
      fill="#B30015"
    />
    <circle cx="64.5" cy="7.5" r="3.5" fill="#F2C94C" />
    <circle cx="49.5" cy="7.5" r="3.5" fill="#F2C94C" />
  </svg>
)

PortfolioBreachEventsIconSVG.defaultProps = {
  width: 96,
  height: 55,
  className: 'portfolio-breach-events-icon',
}

PortfolioBreachEventsIconSVG.propTypes = {
  className: PropTypes.string,
  height: PropTypes.number.isRequired,
  string: PropTypes.string,
  width: PropTypes.number.isRequired,
}

export default PortfolioBreachEventsIconSVG
