import React from 'react'
import classnames from 'classnames'
import './TableRow.scss'

interface TableRowProps {
  accordionChild?: boolean
  accordionParent?: boolean
  children: React.ReactNode
  className?: string
  expanded?: boolean
  hoverable?: boolean
  noBorder?: boolean
  show?: boolean
}

const TableRow = ({
  children,
  className,
  hoverable = false,
  accordionParent = false,
  accordionChild = false,
  expanded = false,
  noBorder = false,
  show = true,
  ...rest
}: TableRowProps) => {
  if (show) {
    return (
      <tr
        className={classnames('v3-tr', className, {
          'no-border': noBorder,
          'accordion-parent': accordionParent,
          'accordion-child': accordionChild,
          expanded,
          hoverable,
        })}
        {...rest}
      >
        {children}
      </tr>
    )
  }
  return null
}

export default TableRow
