import axios, { AxiosRequestConfig } from 'axios'
import { useToastAlert } from 'components/ToastAlert'
import { Button, TextInput, TextInputType } from 'components/common'
import { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import './CreatePartner.scss'
import { ChevronRightSVG } from 'assets/svg'

function CreatePartner() {
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [partnerName, setPartnerName] = useState('')
  const [contactName, setContactName] = useState('')
  const [contactEmail, setContactEmail] = useState('')
  const { setRequestErrorToast } = useToastAlert()

  const handleSubmit = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault()

    const requestOptions: AxiosRequestConfig = {
      method: 'post',
      baseURL: process.env.REACT_APP_AUTH_URL,
      url: `/mcr/admin/partners/create-partner`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: { partnerName, contactName, contactEmail },
    }

    try {
      setLoading(true)
      await axios(requestOptions)
      setLoading(false)
      history.push('/partners')
    } catch (e) {
      setRequestErrorToast(
        'An error was encountered while creating the partner.',
      )
      setLoading(false)
    }
  }

  return (
    <div id="partner-details-container">
      <div className="header-container">
        <Link to="/partners" className="back">
          <ChevronRightSVG size="24" />
          Back
        </Link>
        <h1>Create MCR Partner</h1>
      </div>
      <form className="create-batch-form" onSubmit={handleSubmit}>
        <TextInput
          value={partnerName}
          label="Partner Name"
          type={TextInputType.TextType}
          placeholder="Tester Inc."
          onChange={({ target: { value } }) => setPartnerName(value)}
          fullBorder
        />
        <TextInput
          value={contactName}
          label="Contact Name"
          type={TextInputType.TextType}
          placeholder="John Tester"
          onChange={({ target: { value } }) => setContactName(value)}
          fullBorder
        />
        <TextInput
          value={contactEmail}
          label="Contact Email"
          type={TextInputType.EmailType}
          placeholder="john.tester@aol.com"
          onChange={({ target: { value } }) => setContactEmail(value)}
          fullBorder
        />
        <Button type="submit" loading={loading}>
          Submit
        </Button>
      </form>
    </div>
  )
}

export default CreatePartner
