import React from 'react'
import classnames from 'classnames'
import './TextInput.scss'

export enum TextInputType {
  ButtonType = 'button',
  CheckboxType = 'checkbox',
  ColorType = 'color',
  DateType = 'date',
  DatetimeLocalType = 'datetime-local',
  EmailType = 'email',
  FileType = 'file',
  HiddenType = 'hidden',
  ImageType = 'image',
  MonthType = 'month',
  NumberType = 'number',
  PasswordType = 'password',
  RadioType = 'radio',
  RangeType = 'range',
  ResetType = 'reset',
  SearchType = 'search',
  SubmitType = 'submit',
  TelType = 'tel',
  TextType = 'text',
  TimeType = 'time',
  UrlType = 'url',
  WeekType = 'week',
}
interface TextInputProps {
  autoFocus?: boolean
  className?: string
  disabled?: boolean
  error?: string
  fullBorder?: boolean
  icon?: JSX.Element
  id?: string
  invalidText?: string
  label?: string
  noLabel?: boolean
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  placeholder?: string
  textLinkHref?: string
  textLinkText?: string
  type?: TextInputType
  validText?: string
  value?: string
  step?: number
  max?: number
}

const TextInput = ({
  value = '',
  type = TextInputType.TextType,
  disabled = false,
  placeholder = '',
  id,
  label,
  fullBorder = false,
  invalidText,
  validText,
  onChange,
  icon,
  textLinkText,
  textLinkHref,
  className,
  noLabel = false,
  error = '',
  autoFocus,
  ...rest
}: TextInputProps) => {
  return (
    <div
      className={classnames('v3-text-input', className, {
        'full-border': fullBorder,
        error: !!error,
      })}
    >
      {!noLabel && (
        <label htmlFor={id} className={classnames({ disabled: disabled })}>
          {label}
        </label>
      )}
      <input
        type={type}
        disabled={disabled}
        placeholder={placeholder}
        id={id}
        value={value}
        onChange={onChange}
        autoFocus={autoFocus}
        {...rest}
      />
      <div className="icon-wrapper">{icon}</div>
      <div className="text-link-wrapper">
        <a href={textLinkHref}>{textLinkText}</a>
      </div>
      {!fullBorder && <div className="focus-indicator"></div>}
      {!!error && <div className="error-text">{error}</div>}
      {/* {(value && validText) && <div className="valid-text">{validText}</div>} */}
    </div>
  )
}

export default TextInput
