import React, { ReactNode } from 'react'
// import PropTypes from 'prop-types'
import classnames from 'classnames'
import './Container.scss'

/**
 * `Container` is the most basic building block of Containers. The default, no props, `Container` has 10px border-radius and `25px 30px` padding.
 */

interface ContainerProps {
  borderRadius?: string
  children?: ReactNode
  className?: string
  id?: string
  medium?: boolean
  shadow?: boolean
}

const Container = ({
  children,
  shadow,
  id,
  className,
  borderRadius,
  medium,
  ...rest
}: ContainerProps) => {
  return (
    <div
      id={id}
      style={{ borderRadius }}
      className={classnames('v3-container', className, {
        'box-shadow': shadow,
        medium,
      })}
      {...rest}
    >
      {children}
    </div>
  )
}

// Container.defaultProps = {
//   shadow: false,
// }

// Container.propTypes = {
//   borderRadius: PropTypes.string,
//   children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
//   className: PropTypes.string,
//   id: PropTypes.string,
//   medium: PropTypes.bool,
//   shadow: PropTypes.bool,
// }

export default Container
