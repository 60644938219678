import React from 'react'
import PropTypes from 'prop-types'

const MatrixSVG = ({ size, className }) => (
  <svg
    width={size}
    className={className}
    height={size}
    viewBox="0 0 14 14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.465 12.69V8.897c0-.696-.481-1.265-1.07-1.265h-4.28c-.588 0-1.07.57-1.07 1.265v3.793c0 .696.482 1.265 1.07 1.265h4.28c.589 0 1.07-.57 1.07-1.265zm7.49-3.793v3.793c0 .696-.482 1.265-1.07 1.265h-4.28c-.589 0-1.07-.57-1.07-1.265V8.897c0-.696.481-1.265 1.07-1.265h4.28c.588 0 1.07.57 1.07 1.265zm0-7.587v3.793c0 .696-.482 1.265-1.07 1.265h-4.28c-.589 0-1.07-.57-1.07-1.265V1.31c0-.696.481-1.265 1.07-1.265h4.28c.588 0 1.07.57 1.07 1.265zm-8.56 5.058h-4.28c-.588 0-1.07-.57-1.07-1.265V1.31C.045.614.527.045 1.115.045h4.28c.589 0 1.07.57 1.07 1.265v3.793c0 .696-.481 1.265-1.07 1.265z"
    />
  </svg>
)

MatrixSVG.defaultProps = {
  size: '24',
  className: '',
}

MatrixSVG.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string.isRequired,
}

export default MatrixSVG
