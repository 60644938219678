import React from 'react'
import PropTypes from 'prop-types'

const CalendarTodaySVG = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19a2 2 0 002 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V8h14v11zM7 10h5v5H7z" />
  </svg>
)

CalendarTodaySVG.defaultProps = {
  size: 24,
}

CalendarTodaySVG.propTypes = {
  size: PropTypes.number.isRequired,
}

export default CalendarTodaySVG
