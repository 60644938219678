import React from 'react'
import classnames from 'classnames'
import './TextAreaInput.scss'

const TextAreaInput = ({
  // disabled,
  placeholder,
  id,
  label,
  rows = 5,
  value,
  onChange,
  ...rest
}) => {
  return (
    <div className={classnames('v3-textarea')}>
      <label htmlFor={id}>{label}</label>
      <textarea
        // disabled={disabled}
        placeholder={placeholder}
        rows={rows}
        id={id}
        value={value}
        onChange={onChange}
        {...rest}
      />
    </div>
  )
}

export default TextAreaInput
