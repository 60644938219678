import React from 'react'
import PropTypes from 'prop-types'

const FolderSVG = ({ size, className }) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path fill="none" d="M0 0h24v24H0V0z" />
    <path d="M10.59 4.59C10.21 4.21 9.7 4 9.17 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2h-8l-1.41-1.41z" />
  </svg>
)

FolderSVG.defaultProps = {
  size: '24',
}

FolderSVG.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string.isRequired,
}

export default FolderSVG
