import React from 'react'
import PropTypes from 'prop-types'

const AddNoCircleSVG = ({ size, className, fill }) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 14 14"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <circle id="Ellipse" cx="14.0002" cy="14" r="14" fill="#FFFFFF" />
      <path
        d="M12.2487 6.31881C12.2489 6.14246 12.1789 5.97329 12.0543 5.84859C11.9296 5.7239 11.7604 5.65393 11.584 5.65413L6.97841 5.64941L6.97841 1.03908C6.97861 0.862733 6.90864 0.693554 6.78395 0.568859C6.65925 0.444164 6.49007 0.374199 6.31373 0.374398C5.94603 0.374398 5.64905 0.671383 5.64905 1.03908L5.64905 5.64941L1.03871 5.64941C0.671015 5.64941 0.374031 5.9464 0.37403 6.31409C0.37403 6.68179 0.671015 6.97878 1.03871 6.97878L5.64905 6.97878L5.64905 11.5891C5.64905 11.9568 5.94603 12.2538 6.31373 12.2538C6.68142 12.2538 6.97841 11.9568 6.97841 11.5891L6.97841 6.97878L11.5887 6.97878C11.947 6.97878 12.2487 6.67708 12.2487 6.31881Z"
        fill={fill}
      />
    </g>
  </svg>
)

AddNoCircleSVG.defaultProps = {
  size: '14',
  fill: '#D5DDE4',
}

AddNoCircleSVG.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string.isRequired,
  fill: PropTypes.string,
}

export default AddNoCircleSVG
