import ActiveLicensesReport from './ActiveLicensesReport'
import ComplianceReport from './ComplianceReport'
import './Reports.scss'

export default function Reports() {
  return (
    <div className="reports-container">
      <div className="reports-header-container">
        <h1>Reports</h1>
      </div>
      <div>
        <ComplianceReport />
        <ActiveLicensesReport />
      </div>
    </div>
  )
}
