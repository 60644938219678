import React from 'react'
import PropTypes from 'prop-types'

const HamburgerSVG = ({ size, fill }) => (
  <svg
    width={size}
    height={size}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 0H7V1.4H0V0Z" fill={fill} />
    <path d="M0 2.79989H7V4.19989H0V2.79989Z" fill={fill} />
    <path d="M0 5.59977H7V6.99977H0V5.59977Z" fill={fill} />
  </svg>
)

HamburgerSVG.defaultProps = {
  size: '12',
  fill: '#173A56',
}

HamburgerSVG.propTypes = {
  fill: PropTypes.string,
  size: PropTypes.string.isRequired,
}

export default HamburgerSVG
