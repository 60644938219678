import React from 'react'
import PropTypes from 'prop-types'

const InfoSVG = ({ size, className, ...rest }) => (
  <svg
    {...rest}
    width={size}
    className={className}
    height={size}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path fill="none" d="M0 0h24v24H0V0z" />
    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 15c-.55 0-1-.45-1-1v-4c0-.55.45-1 1-1s1 .45 1 1v4c0 .55-.45 1-1 1zm1-8h-2V7h2v2z" />
  </svg>
)

InfoSVG.defaultProps = {
  size: '24',
  className: '',
}

InfoSVG.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string.isRequired,
}

export default InfoSVG
