import React from 'react'
import PropTypes from 'prop-types'

const PersonPinCircleSVG = ({ size, fill }) => (
  <svg
    width={size}
    height={size}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 2.076a6.424 6.424 0 00-6.417 6.417c0 3.822 4.052 9.093 5.72 11.1a.91.91 0 001.403 0c1.66-2.007 5.71-7.277 5.71-11.1A6.424 6.424 0 0011 2.076zm0 1.834a1.833 1.833 0 11.001 3.668 1.833 1.833 0 010-3.668zm-3.667 7.195A4.384 4.384 0 0011 13.076c1.531 0 2.879-.779 3.667-1.97-.018-1.21-2.448-1.88-3.667-1.88-1.219 0-3.648.67-3.667 1.88z"
      fill={fill}
    />
  </svg>
)

PersonPinCircleSVG.defaultProps = {
  size: '24',
  fill: '#173A56',
}

PersonPinCircleSVG.propTypes = {
  fill: PropTypes.string,
  size: PropTypes.string.isRequired,
}

export default PersonPinCircleSVG
