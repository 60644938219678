import React, { useEffect, useState } from 'react'
import axios, { AxiosRequestConfig } from 'axios'
import { Redirect } from 'react-router-dom'
import NavBar from 'components/AdminDashboard/NavBar'
import MainContainer from 'components/AdminDashboard/MainContainer'
import { Loading } from 'components/common'
import './AdminDashboard.scss'
import KeepAlive from './KeepAlive'

const requestOptions: AxiosRequestConfig = {
  method: 'get',
  baseURL: process.env.REACT_APP_AUTH_URL,
  url: '/mcr/admin/verify',
}

function AdminDashboard() {
  const [loaded, setLoaded] = useState(false)
  const [authorized, setAuthorized] = useState(false)

  useEffect(() => {
    const verifyUser = async () => {
      try {
        await axios(requestOptions)
        setAuthorized(true)
      } catch (e) {
        setAuthorized(false)
        console.log(e)
      }
      setLoaded(true)
    }
    verifyUser()
  }, [])

  if (!loaded) return <Loading isLoading overlay />

  if (!authorized) return <Redirect to="/login" />

  return (
    <div className="admin-dashboard">
      <NavBar />
      <MainContainer />
      <KeepAlive />
    </div>
  )
}

export default AdminDashboard
