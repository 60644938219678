import React from 'react'
import classnames from 'classnames'
import './RadioInput.scss'

/**
 * This is NOT fully fleshed out. I needed a basic setup for the single toe view.
 */
const RadioInput = ({
  value,
  onChange,
  className,
  disabled,
  id,
  name,
  checked,
  showHover,
  readOnly,
  label,
  ...rest
}: {
  value?: string | number
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  className?: string
  disabled?: boolean
  id?: string
  name?: string
  checked?: boolean
  showHover?: boolean
  readOnly?: boolean
  label?: string
}) => {
  return (
    <>
      <input
        type="radio"
        disabled={disabled}
        id={id}
        value={value}
        name={name}
        checked={checked}
        onChange={onChange}
        readOnly={readOnly}
        {...rest}
      />
      <label
        htmlFor={id}
        className={classnames(`v3-radio-input ${showHover ? 'hover' : ''}`)}
      >
        {label}
      </label>
    </>
  )
}

// RadioInput.defaultProps = {
//   value: '',
//   id: '',
//   className: '',
//   name: '',
//   showHover: false,
// }

// RadioInput.propTypes = {
//   checked: PropTypes.bool.isRequired,
//   className: PropTypes.string,
//   disabled: PropTypes.bool,
//   id: PropTypes.string,
//   name: PropTypes.string.isRequired,
//   onClick: PropTypes.func.isRequired,
//   readOnly: PropTypes.any.isRequired,
//   showHover: PropTypes.bool.isRequired,
//   value: PropTypes.string.isRequired,
// }

export default RadioInput
