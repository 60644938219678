import React from 'react'
import classnames from 'classnames'
import './TableHead.scss'

interface TableHeadProps {
  children: React.ReactNode
  className?: string
}

const TableHead = ({ children, className, ...rest }: TableHeadProps) => {
  return (
    <thead className={classnames('v3-thead', className)} {...rest}>
      {children}
    </thead>
  )
}

export default TableHead
