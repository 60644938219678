import React from 'react'
import PropTypes from 'prop-types'

const DisabledForKeySVG = ({ width, height }) => (
  <svg
    className="ignored-key-icon"
    width={width}
    height={height}
    viewBox="0 0 55 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.5928 24H18.2256L14.582 19.1533L13.5361 20.083V24H12.374V14.0059H13.5361V18.9619L18.0684 14.0059H19.4424L15.4229 18.3467L19.5928 24ZM23.6878 24.1367C22.5804 24.1367 21.7054 23.7995 21.0628 23.125C20.4248 22.4505 20.1057 21.514 20.1057 20.3154C20.1057 19.1077 20.402 18.1484 20.9944 17.4375C21.5914 16.7266 22.3912 16.3711 23.3938 16.3711C24.3326 16.3711 25.0755 16.681 25.6223 17.3008C26.1692 17.916 26.4427 18.7295 26.4427 19.7412V20.459H21.2815C21.3043 21.3385 21.5253 22.0062 21.9446 22.4619C22.3684 22.9176 22.9632 23.1455 23.7288 23.1455C24.5354 23.1455 25.333 22.9769 26.1214 22.6396V23.6514C25.7203 23.8245 25.3398 23.9476 24.9798 24.0205C24.6243 24.098 24.1936 24.1367 23.6878 24.1367ZM23.3802 17.3213C22.7786 17.3213 22.2978 17.5173 21.9378 17.9092C21.5823 18.3011 21.3727 18.8434 21.3089 19.5361H25.2259C25.2259 18.8206 25.0664 18.2738 24.7473 17.8955C24.4283 17.5127 23.9726 17.3213 23.3802 17.3213ZM26.9146 16.5078H28.1314L29.772 20.7803C30.1321 21.7555 30.3554 22.4596 30.442 22.8926H30.4966C30.5559 22.6602 30.6789 22.2637 30.8658 21.7031C31.0572 21.138 31.677 19.4062 32.7252 16.5078H33.942L30.7222 25.0391C30.4032 25.8822 30.0295 26.4792 29.6011 26.8301C29.1773 27.1855 28.6555 27.3633 28.0357 27.3633C27.6893 27.3633 27.3476 27.3245 27.0103 27.2471V26.3379C27.261 26.3926 27.5412 26.4199 27.8511 26.4199C28.6304 26.4199 29.1864 25.9824 29.5191 25.1074L29.9361 24.041L26.9146 16.5078Z"
      fill="#D5DDE4"
    />
    <mask id="path-2-inside-1" fill="white">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45 14.9291C44.6734 14.9758 44.3395 15 44 15C40.134 15 37 11.866 37 8C37 7.66048 37.0242 7.3266 37.0709 7H4C1.79086 7 0 8.79086 0 11V29C0 31.2091 1.79086 33 4 33H41C43.2091 33 45 31.2091 45 29V14.9291Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M45 14.9291C44.6734 14.9758 44.3395 15 44 15C40.134 15 37 11.866 37 8C37 7.66048 37.0242 7.3266 37.0709 7H4C1.79086 7 0 8.79086 0 11V29C0 31.2091 1.79086 33 4 33H41C43.2091 33 45 31.2091 45 29V14.9291Z"
      fill="#D5DDE4"
      fillOpacity="0.1"
    />
    <path
      d="M45 14.9291H46V13.7759L44.8584 13.9392L45 14.9291ZM37.0709 7L38.0608 7.1416L38.2241 6H37.0709V7ZM44.8584 13.9392C44.5786 13.9792 44.292 14 44 14V16C44.387 16 44.7682 15.9724 45.1416 15.919L44.8584 13.9392ZM44 14C40.6863 14 38 11.3137 38 8H36C36 12.4183 39.5817 16 44 16V14ZM38 8C38 7.708 38.0208 7.42144 38.0608 7.1416L36.081 6.8584C36.0276 7.23176 36 7.61296 36 8H38ZM4 8H37.0709V6H4V8ZM1 11C1 9.34315 2.34315 8 4 8V6C1.23858 6 -1 8.23858 -1 11H1ZM1 29V11H-1V29H1ZM4 32C2.34315 32 1 30.6569 1 29H-1C-1 31.7614 1.23858 34 4 34V32ZM41 32H4V34H41V32ZM44 29C44 30.6569 42.6569 32 41 32V34C43.7614 34 46 31.7614 46 29H44ZM44 14.9291V29H46V14.9291H44Z"
      fill="#D5DDE4"
      mask="url(#path-2-inside-1)"
    />
    <path
      d="M47.4337 6.66667C46.887 5.11333 45.407 4 43.667 4C41.4603 4 39.667 5.79333 39.667 8C39.667 10.2067 41.4603 12 43.667 12C45.407 12 46.887 10.8867 47.4337 9.33333H50.3337V12H53.0003V9.33333H54.3337V6.66667H47.4337ZM43.667 9.33333C42.9337 9.33333 42.3337 8.73333 42.3337 8C42.3337 7.26667 42.9337 6.66667 43.667 6.66667C44.4003 6.66667 45.0003 7.26667 45.0003 8C45.0003 8.73333 44.4003 9.33333 43.667 9.33333Z"
      fill="#D5DDE4"
    />
  </svg>
)

DisabledForKeySVG.defaultProps = {
  width: '55',
  height: '33',
}

DisabledForKeySVG.propTypes = {
  height: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
}

export default DisabledForKeySVG
