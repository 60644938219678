import axios, { AxiosRequestConfig } from 'axios'
import { useState, useEffect, useRef } from 'react'
import { Switch, Route } from 'react-router-dom'
import UserDetails from './UserDetails/UserDetails'
import UsersTable from './UsersTable'
import { useToastAlert } from 'components/ToastAlert'

export interface User {
  user_id: number
  is_active: boolean
  first_name: string
  last_name: string
  email: string
  last_sign_in_at: string | null
  sso_provider: string
  sso_uid: string
  customer_id: string
  customer: string
  login_uri: string
  toe: string | null
  ctar_id: string | null
  created_at: string
  access_code: string | null
  ac_used_at: string | null
  partner_name: string | null
}
export interface TableConfig {
  method: 'asc_nulls_last' | 'desc_nulls_last'
  field: string
  activePage: number
  totalPages: number
}

function Users() {
  const tableContainerEl = useRef<HTMLDivElement>(null)
  const [loaded, setLoaded] = useState<boolean>(false)
  const [users, setUsers] = useState<User[]>([])
  const [tableConfig, setTableConfig] = useState<TableConfig>({
    method: 'desc_nulls_last',
    field: 'last_sign_in_at',
    activePage: 1,
    totalPages: 1,
  })
  const { setRequestErrorToast } = useToastAlert()

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        if (tableContainerEl !== null && tableContainerEl.current !== null) {
          tableContainerEl.current.scrollTo(0, 0)
        }
        setLoaded(false)
        const requestOptions: AxiosRequestConfig = {
          method: 'get',
          baseURL: process.env.REACT_APP_AUTH_URL,
          url: '/mcr/admin/users',
          params: {
            method: tableConfig.method,
            field: tableConfig.field,
            page: tableConfig.activePage,
            perPage: 500,
          },
        }
        const { data } = await axios(requestOptions)
        setUsers(data.results)
        setTableConfig((prevTableConfig) => ({
          ...prevTableConfig,
          totalPages: data.page_count,
        }))
        setLoaded(true)
      } catch (e) {
        setRequestErrorToast('There was a problem fetching the users.')
        setLoaded(true)
        console.log(e)
      }
    }
    fetchUsers()
  }, [
    tableConfig.method,
    tableConfig.field,
    tableConfig.activePage,
    setLoaded,
    setUsers,
    setRequestErrorToast,
  ])

  const changeSort = (id: string): void => {
    if (id === tableConfig.field) {
      setTableConfig((prevTableConfig) =>
        prevTableConfig.method === 'asc_nulls_last'
          ? { ...prevTableConfig, method: 'desc_nulls_last' }
          : { ...prevTableConfig, method: 'asc_nulls_last' },
      )
    } else {
      setTableConfig((prevTableConfig) => ({
        ...prevTableConfig,
        method: 'asc_nulls_last',
        field: id,
      }))
    }
  }

  const changePage = (
    e: React.MouseEvent<HTMLButtonElement>,
    { activePage }: { activePage: number },
  ): void => {
    setTableConfig((prevTableConfig) => ({
      ...prevTableConfig,
      activePage: activePage,
    }))
  }

  return (
    <Switch>
      <Route
        exact
        path="/users"
        render={() => (
          <UsersTable
            loaded={loaded}
            users={users}
            changeSort={changeSort}
            changePage={changePage}
            tableConfig={tableConfig}
            ref={tableContainerEl}
          />
        )}
      />
      <Route
        path="/users/:user_id"
        render={() => (
          <UserDetails loaded={loaded} users={users} setUsers={setUsers} />
        )}
      />
    </Switch>
  )
}

export default Users
