import React from 'react'
import PropTypes from 'prop-types'

const ExpandChevronSVG = ({ width, height, classname }) => (
  <svg
    className={classname}
    width={width}
    height={height}
    viewBox="0 0 12 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.87501 1.00001L5.99501 4.88001L2.11501 1.00001C1.92817 0.812752 1.67452 0.70752 1.41001 0.70752C1.14549 0.70752 0.891838 0.812752 0.705006 1.00001C0.315006 1.39001 0.315006 2.02001 0.705006 2.41001L5.29501 7.00001C5.68501 7.39001 6.31501 7.39001 6.70501 7.00001L11.295 2.41001C11.685 2.02001 11.685 1.39001 11.295 1.00001C10.905 0.620005 10.265 0.610005 9.87501 1.00001Z"
      fill="#2F80ED"
    />
  </svg>
)

ExpandChevronSVG.defaultProps = {
  width: '12',
  height: '8',
  classname: '',
}

ExpandChevronSVG.propTypes = {
  classname: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
}

export default ExpandChevronSVG
