import React from 'react'
import PropTypes from 'prop-types'

const KeyFindingSVG = ({ className, ...rest }) => (
  <svg
    width="55"
    height="33"
    viewBox="0 0 55 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.5928 24H18.2256L14.582 19.1533L13.5361 20.083V24H12.374V14.0059H13.5361V18.9619L18.0684 14.0059H19.4424L15.4229 18.3467L19.5928 24ZM23.6878 24.1367C22.5804 24.1367 21.7054 23.7995 21.0628 23.125C20.4248 22.4505 20.1057 21.514 20.1057 20.3154C20.1057 19.1077 20.402 18.1484 20.9944 17.4375C21.5914 16.7266 22.3912 16.3711 23.3938 16.3711C24.3326 16.3711 25.0755 16.681 25.6223 17.3008C26.1692 17.916 26.4427 18.7295 26.4427 19.7412V20.459H21.2815C21.3043 21.3385 21.5253 22.0062 21.9446 22.4619C22.3684 22.9176 22.9632 23.1455 23.7288 23.1455C24.5354 23.1455 25.333 22.9769 26.1214 22.6396V23.6514C25.7203 23.8245 25.3398 23.9476 24.9798 24.0205C24.6243 24.098 24.1936 24.1367 23.6878 24.1367ZM23.3802 17.3213C22.7786 17.3213 22.2978 17.5173 21.9378 17.9092C21.5823 18.3011 21.3727 18.8434 21.3089 19.5361H25.2259C25.2259 18.8206 25.0664 18.2738 24.7473 17.8955C24.4283 17.5127 23.9726 17.3213 23.3802 17.3213ZM26.9146 16.5078H28.1314L29.772 20.7803C30.1321 21.7555 30.3554 22.4596 30.442 22.8926H30.4966C30.5559 22.6602 30.6789 22.2637 30.8658 21.7031C31.0572 21.138 31.677 19.4062 32.7252 16.5078H33.942L30.7222 25.0391C30.4032 25.8822 30.0295 26.4792 29.6011 26.8301C29.1773 27.1855 28.6555 27.3633 28.0357 27.3633C27.6893 27.3633 27.3476 27.3245 27.0103 27.2471V26.3379C27.261 26.3926 27.5412 26.4199 27.8511 26.4199C28.6304 26.4199 29.1864 25.9824 29.5191 25.1074L29.9361 24.041L26.9146 16.5078Z"
      fill="#B30015"
    />
    <mask id="path-2-inside-1" fill="white">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.0709 7H4C1.79086 7 0 8.79086 0 11V29C0 31.2091 1.79086 33 4 33H41C43.2091 33 45 31.2091 45 29V14.9291C44.6734 14.9758 44.3395 15 44 15V29C44 30.6569 42.6569 32 41 32H4C2.34315 32 1 30.6569 1 29V11C1 9.34315 2.34315 8 4 8H37C37 7.66048 37.0242 7.3266 37.0709 7Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M37.0709 7H4C1.79086 7 0 8.79086 0 11V29C0 31.2091 1.79086 33 4 33H41C43.2091 33 45 31.2091 45 29V14.9291C44.6734 14.9758 44.3395 15 44 15V29C44 30.6569 42.6569 32 41 32H4C2.34315 32 1 30.6569 1 29V11C1 9.34315 2.34315 8 4 8H37C37 7.66048 37.0242 7.3266 37.0709 7Z"
      fill="#B30015"
      fillOpacity="0.1"
    />
    <path
      d="M37.0709 7L38.0608 7.1416L38.2241 6H37.0709V7ZM45 14.9291H46V13.7759L44.8584 13.9392L45 14.9291ZM44 15V14H43V15H44ZM37 8V9H38V8H37ZM37.0709 6H4V8H37.0709V6ZM4 6C1.23858 6 -1 8.23858 -1 11H1C1 9.34315 2.34315 8 4 8V6ZM-1 11V29H1V11H-1ZM-1 29C-1 31.7614 1.23858 34 4 34V32C2.34315 32 1 30.6569 1 29H-1ZM4 34H41V32H4V34ZM41 34C43.7614 34 46 31.7614 46 29H44C44 30.6569 42.6569 32 41 32V34ZM46 29V14.9291H44V29H46ZM44.8584 13.9392C44.5786 13.9792 44.292 14 44 14V16C44.387 16 44.7682 15.9724 45.1416 15.919L44.8584 13.9392ZM45 29V15H43V29H45ZM41 33C43.2091 33 45 31.2091 45 29H43C43 30.1046 42.1046 31 41 31V33ZM4 33H41V31H4V33ZM0 29C0 31.2091 1.79086 33 4 33V31C2.89543 31 2 30.1046 2 29H0ZM0 11V29H2V11H0ZM4 7C1.79086 7 0 8.79086 0 11H2C2 9.89543 2.89543 9 4 9V7ZM37 7H4V9H37V7ZM38 8C38 7.708 38.0208 7.42144 38.0608 7.1416L36.081 6.8584C36.0276 7.23176 36 7.61296 36 8H38Z"
      fill="#B30015"
      mask="url(#path-2-inside-1)"
    />
    <path
      d="M47.4327 6.66667C46.886 5.11333 45.406 4 43.666 4C41.4593 4 39.666 5.79333 39.666 8C39.666 10.2067 41.4593 12 43.666 12C45.406 12 46.886 10.8867 47.4327 9.33333H50.3327V12H52.9993V9.33333H54.3327V6.66667H47.4327ZM43.666 9.33333C42.9327 9.33333 42.3327 8.73333 42.3327 8C42.3327 7.26667 42.9327 6.66667 43.666 6.66667C44.3993 6.66667 44.9993 7.26667 44.9993 8C44.9993 8.73333 44.3993 9.33333 43.666 9.33333Z"
      fill="#F2C94C"
    />
  </svg>
)

KeyFindingSVG.defaultProps = {
  className: '',
}

KeyFindingSVG.propTypes = {
  className: PropTypes.string,
}

export default KeyFindingSVG
