import React from 'react'
import PropTypes from 'prop-types'

const LinkIcon = ({ className, width, height, fill }) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 23 23"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.70817 14.3752H9.58317C10.1103 14.3752 10.5415 14.8064 10.5415 15.3335C10.5415 15.8606 10.1103 16.2918 9.58317 16.2918H6.70817C4.06317 16.2918 1.9165 14.1452 1.9165 11.5002C1.9165 8.85516 4.06317 6.7085 6.70817 6.7085H9.58317C10.1103 6.7085 10.5415 7.13975 10.5415 7.66683C10.5415 8.19391 10.1103 8.62516 9.58317 8.62516H6.70817C5.12692 8.62516 3.83317 9.91891 3.83317 11.5002C3.83317 13.0814 5.12692 14.3752 6.70817 14.3752ZM16.2915 6.7085H13.4165C12.8894 6.7085 12.4582 7.13975 12.4582 7.66683C12.4582 8.19391 12.8894 8.62516 13.4165 8.62516H16.2915C17.8728 8.62516 19.1665 9.91891 19.1665 11.5002C19.1665 13.0814 17.8728 14.3752 16.2915 14.3752H13.4165C12.8894 14.3752 12.4582 14.8064 12.4582 15.3335C12.4582 15.8606 12.8894 16.2918 13.4165 16.2918H16.2915C18.9365 16.2918 21.0832 14.1452 21.0832 11.5002C21.0832 8.85516 18.9365 6.7085 16.2915 6.7085ZM7.6665 11.5002C7.6665 12.0272 8.09775 12.4585 8.62484 12.4585H14.3748C14.9019 12.4585 15.3332 12.0272 15.3332 11.5002C15.3332 10.9731 14.9019 10.5418 14.3748 10.5418H8.62484C8.09775 10.5418 7.6665 10.9731 7.6665 11.5002Z"
      fill="#2F80ED"
    />
  </svg>
)

LinkIcon.defaultProps = {
  width: 23,
  height: 23,
  className: '',
}

LinkIcon.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
}

export default LinkIcon
