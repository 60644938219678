import React from 'react'
import classnames from 'classnames'
import './TableCellHeader.scss'
import AngleDown from '../../../assets/svg/AngleDown'

interface TableCellHeaderProps {
  align?: string
  children?: React.ReactNode
  className?: string
  header?: boolean
  sortDirection?: 'asc' | 'desc' | 'asc_nulls_last' | 'desc_nulls_last'
  sortable?: boolean
  sorted?: boolean
  sticky?: boolean
  onClick?: React.MouseEventHandler<HTMLTableHeaderCellElement>
}

const TableCellHeader = ({
  children,
  className,
  header = false,
  sortable = false,
  sorted = false,
  sortDirection,
  align = 'left',
  sticky = false,
  onClick,
  ...rest
}: TableCellHeaderProps) => {
  if (sortable) {
    return (
      <th
        className={classnames('v3-th', className, align, {
          sortable,
          sticky,
        })}
        onClick={onClick}
        {...rest}
      >
        <div className="th-wrapper">
          <div
            className={classnames('th-text-svg-wrapper', sortDirection, align, {
              sorted,
            })}
          >
            {children}
            <AngleDown
              className="v3-th-angle-svg"
              fill={sorted ? '#173A56' : '#B5CDE3'}
            />
          </div>
        </div>
      </th>
    )
  }
  return (
    <th className={classnames('v3-th', className, align)} {...rest}>
      {children}
    </th>
  )
}

export default TableCellHeader
