import React from 'react'
import classnames from 'classnames'
import './Toggle.scss'

interface ToggleProps {
  checked: boolean
  id?: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const Toggle = ({ id, checked, onChange }: ToggleProps) => {
  return (
    <div className={classnames('toggle-wrapper')}>
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
        id={id}
        className="tgl"
      />
      <label htmlFor={id} className="tgl-btn" />
    </div>
  )
}

export default Toggle
