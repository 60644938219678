import React from 'react'
import './Checkbox.scss'

const Checkbox = ({
  id,
  checked,
  onChange,
  disabled,
  label,
}: {
  id?: string
  checked: boolean
  onChange: () => void
  disabled?: boolean
  label?: string
}) => {
  return (
    <div className="checkbox-container">
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
        id={id}
        disabled={disabled}
      />
      <label htmlFor={id}>{label}</label>
    </div>
  )
}

export default Checkbox
