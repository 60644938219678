import axios, { AxiosRequestConfig } from 'axios'
import { useState } from 'react'
import { TextInput, TextInputType, Button } from 'components/common'
import ToeSearchResultsTable from './ToeSearchResultsTable'
import { CTAR } from './AssociatedToes'
import { useToastAlert } from 'components/ToastAlert'
import './AddAssociatedToe.scss'

export interface TOE {
  id: string
  name: string
  updated_at: string
}

interface AddAssociatedToeProps {
  customer_id: string
  customer_short_name: string
  setCtars: React.Dispatch<React.SetStateAction<CTAR[]>>
}

function AddAssociatedToe({
  customer_id,
  setCtars,
  customer_short_name,
}: AddAssociatedToeProps) {
  const [query, setQuery] = useState('')
  const [searching, setSearching] = useState(false)
  const [toes, setToes] = useState<TOE[]>([])
  const { setRequestErrorToast } = useToastAlert()

  const handleSubmit = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault()
    try {
      setSearching(true)
      const requestOptions: AxiosRequestConfig = {
        method: 'get',
        baseURL: process.env.REACT_APP_AUTH_URL,
        url: '/mcr/admin/search-toes',
        params: {
          query,
        },
      }
      const { data } = await axios(requestOptions)
      setToes(data)
    } catch (e) {
      setRequestErrorToast('There was a problem fetching the toes.')
      console.log(e)
    }
    setSearching(false)
  }

  return (
    <div id="add-associated-toe">
      <h3>Add Associated Toe</h3>
      <form className="search-form" onSubmit={handleSubmit}>
        <TextInput
          value={query}
          label="Search"
          type={TextInputType.SearchType}
          placeholder="Search toe short_name..."
          onChange={({ target: { value } }) => setQuery(value)}
          fullBorder
        />
        <Button type="submit" loading={searching}>
          Search
        </Button>
      </form>
      <ToeSearchResultsTable
        toes={toes}
        setCtars={setCtars}
        customer_id={customer_id}
        customer_short_name={customer_short_name}
      />
    </div>
  )
}

export default AddAssociatedToe
