import { ChevronRightSVG } from 'assets/svg'
import { Link, useParams } from 'react-router-dom'
import './BatchDetails.scss'
import BatchInfo from './BatchInfo'
import AccessCodes from './AccessCodes'

const BatchDetails = () => {
  const { partner_id } = useParams<{
    partner_id?: string
    batch_id?: string
  }>()

  return (
    <div id="batch-details-container">
      <div className="header-container">
        <Link to={`/partners/${partner_id}`} className="back">
          <ChevronRightSVG size="24" />
          Back
        </Link>
        <h1>Batch Details</h1>
      </div>
      <BatchInfo />
      <AccessCodes />
    </div>
  )
}

export default BatchDetails
