import { useToastAlert } from 'components/ToastAlert'
import { parseISO, format } from 'date-fns'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Partner } from '../Partners'
import axios, { AxiosRequestConfig } from 'axios'

interface IPartnerInfo extends Partner {
  id: number
  updated_at: string
}

function PartnerInfo() {
  const { partner_id } = useParams<{ partner_id?: string }>()
  const { setRequestErrorToast } = useToastAlert()
  const [loaded, setLoaded] = useState<boolean>(false)
  const [partner, setPartner] = useState<IPartnerInfo | null>(null)

  useEffect(() => {
    const fetchPartnerInfo = async () => {
      try {
        setLoaded(false)
        const requestOptions: AxiosRequestConfig = {
          method: 'get',
          baseURL: process.env.REACT_APP_AUTH_URL,
          url: `/mcr/admin/partner/${partner_id}`,
        }
        const { data } = await axios(requestOptions)
        setPartner(data)
        setLoaded(true)
      } catch (e) {
        setRequestErrorToast('There was a problem fetching partner info.')
        setLoaded(true)
        console.log(e)
      }
    }
    fetchPartnerInfo()
  }, [partner_id, setRequestErrorToast])

  if (!loaded) return <div>Loading...</div>

  return (
    <div>
      <h2>Info</h2>
      <table className="partner-details">
        <tbody>
          <tr>
            <td className="label">Partner Name:</td>
            <td className="data">{partner?.partner_name}</td>
          </tr>
          <tr>
            <td className="label">Partner Id:</td>
            <td className="data">{partner?.id}</td>
          </tr>
          <tr>
            <td className="label">Contact Name:</td>
            <td className="data">{partner?.contact_name}</td>
          </tr>
          <tr>
            <td className="label">Contact Email:</td>
            <td className="data">
              <a href={`mailto:${partner?.contact_email}`}>
                {partner?.contact_email}
              </a>
            </td>
          </tr>
          <tr>
            <td className="label">Created At:</td>
            <td className="data">
              {partner
                ? format(
                    parseISO(partner.inserted_at),
                    'MMM d, yyyy hh:mm aaa zzz',
                  )
                : `Hasn't signed in`}
            </td>
          </tr>
          <tr>
            <td className="label">Last Updated At:</td>
            <td className="data">
              {partner
                ? format(
                    parseISO(partner.updated_at),
                    'MMM d, yyyy hh:mm aaa zzz',
                  )
                : `Hasn't signed in`}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default PartnerInfo
