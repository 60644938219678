import { forwardRef } from 'react'
import { CSVLink } from 'react-csv'
import { format, parseISO } from 'date-fns'
import classnames from 'classnames'
import { useHistory } from 'react-router-dom'
import {
  Loading,
  Table,
  TableBody,
  TableHead,
  TableCellHeader,
  TableRow,
  TableCellData,
  Button,
  Pagination,
} from 'components/common'
import { TableConfig, User } from './Users'
import './UsersTable.scss'

interface UsersTableProps {
  loaded: boolean
  users: User[]
  changeSort: (id: string) => void
  changePage: (
    e: React.MouseEvent<HTMLButtonElement>,
    {
      activePage,
      totalPages,
    }: {
      activePage: number
      totalPages: number
    },
  ) => void
  tableConfig: TableConfig
}

const tableHeaders = [
  { label: 'User ID', id: 'id', key: 'user_id' },
  { label: 'Active', id: 'is_active', key: 'is_active' },
  { label: 'First Name', id: 'first_name', key: 'first_name' },
  { label: 'Last Name', id: 'last_name', key: 'last_name' },
  { label: 'Email', id: 'email', key: 'email' },
  { label: 'Associated TOE', id: 'toe_short_name', key: 'toe' },
  { label: 'Last Sign In At', id: 'last_sign_in_at', key: 'last_sign_in_at' },
  { label: 'Created At', id: 'created_at', key: 'created_at' },
  { label: 'SSO Provider', id: 'sso_provider', key: 'sso_provider' },
  { label: 'SSO UID', id: 'sso_uid', key: 'sso_uid' },
  { label: 'Customer ID', id: 'customer_id', key: 'customer_id' },
  { label: 'Customer Name', id: 'short_name', key: 'customer' },
  { label: 'Login URI', id: 'login_uri', key: 'login_uri' },
  { label: 'Access Code', id: 'access_code', key: 'access_code' },
  { label: 'AC Used At', id: 'ac_used_at', key: 'ac_used_at' },
  { label: 'Partner Name', id: 'partner_name', key: 'partner_name' },
]

const UsersTable = forwardRef<HTMLDivElement, UsersTableProps>(
  ({ loaded, users, changeSort, changePage, tableConfig }, ref) => {
    const history = useHistory()

    return (
      <div className="users-table-container">
        <div className="users-table-header">
          <h1>Users Table</h1>
          <div className="dl-csv-button-container">
            {loaded && (
              <CSVLink
                data={users}
                headers={tableHeaders}
                filename="mcr-users-table.csv"
                target="_blank"
                className="download-csv-button"
              >
                Download CSV
              </CSVLink>
            )}
          </div>
        </div>
        <div className="table-container" ref={ref}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCellHeader></TableCellHeader>
                {tableHeaders.map(({ label, id }) => (
                  <TableCellHeader
                    key={id}
                    sortable
                    sorted={id === tableConfig.field}
                    sortDirection={tableConfig.method}
                    onClick={() => changeSort(id)}
                  >
                    {label}
                  </TableCellHeader>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((u) => {
                return (
                  <TableRow
                    key={`${u.user_id}-${u.toe}`}
                    className={classnames({ inactive: !u.is_active })}
                  >
                    <TableCellData className="edit-button-cell">
                      <Button
                        color="green outline"
                        onClick={() => history.push(`users/${u.user_id}`)}
                      >
                        Edit
                      </Button>
                    </TableCellData>
                    <TableCellData>{u.user_id}</TableCellData>
                    <TableCellData
                      className={classnames('is_active', {
                        active: u.is_active,
                      })}
                    >
                      {String(u.is_active)}
                    </TableCellData>
                    <TableCellData>{u.first_name}</TableCellData>
                    <TableCellData>{u.last_name}</TableCellData>
                    <TableCellData className="email">
                      <a href={`mailto:${u.email}`}>{u.email}</a>
                    </TableCellData>
                    <TableCellData>
                      {u.toe ? (
                        <span>{u.toe}</span>
                      ) : (
                        <span className="no-associated-toe">
                          No associated toe
                        </span>
                      )}
                    </TableCellData>
                    <TableCellData>
                      {u.last_sign_in_at
                        ? format(
                            parseISO(`${u.last_sign_in_at}+00:00`),
                            'MMM d, yyyy hh:mm aaa zzz',
                          )
                        : "-- Hasn't signed in --"}
                    </TableCellData>
                    <TableCellData>
                      {format(
                        parseISO(`${u.created_at}+00:00`),
                        'MMM d, yyyy hh:mm aaa zzz',
                      )}
                    </TableCellData>
                    <TableCellData>{u.sso_provider}</TableCellData>
                    <TableCellData>{u.sso_uid}</TableCellData>
                    <TableCellData>{u.customer_id}</TableCellData>
                    <TableCellData>{u.customer}</TableCellData>
                    <TableCellData>
                      <a href={u.login_uri} target="_blank" rel="noreferrer">
                        {u.login_uri}
                      </a>
                    </TableCellData>
                    <TableCellData>{u.access_code}</TableCellData>
                    <TableCellData>
                      {u.ac_used_at
                        ? format(
                            parseISO(`${u.ac_used_at}+00:00`),
                            'MMM d, yyyy hh:mm aaa zzz',
                          )
                        : ''}
                    </TableCellData>
                    <TableCellData>{u.partner_name}</TableCellData>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
          <Loading isLoading={!loaded} overlay />
        </div>
        <div className="table-controls">
          <Pagination
            activePage={tableConfig.activePage}
            onPageChange={changePage}
            totalPages={tableConfig.totalPages}
          />
        </div>
      </div>
    )
  },
)

export default UsersTable
