import React from 'react'
import PropTypes from 'prop-types'

const QuestionMarkOSVG = ({ size, ...rest }) => (
  <svg
    {...rest}
    width={size}
    height={size}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="icon/action/help_24px">
      <path
        id="icon/action/help_24px_2"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99984 1.66699C5.39984 1.66699 1.6665 5.40033 1.6665 10.0003C1.6665 14.6003 5.39984 18.3337 9.99984 18.3337C14.5998 18.3337 18.3332 14.6003 18.3332 10.0003C18.3332 5.40033 14.5998 1.66699 9.99984 1.66699ZM9.1665 15.8337V14.167H10.8332V15.8337H9.1665ZM11.8082 10.142L12.5582 9.37533C13.0332 8.90033 13.3332 8.23366 13.3332 7.50033C13.3332 5.65866 11.8415 4.16699 9.99984 4.16699C8.15817 4.16699 6.6665 5.65866 6.6665 7.50033H8.33317C8.33317 6.58366 9.08317 5.83366 9.99984 5.83366C10.9165 5.83366 11.6665 6.58366 11.6665 7.50033C11.6665 7.95866 11.4832 8.37533 11.1748 8.67532L10.1415 9.72533C9.5415 10.3337 9.1665 11.167 9.1665 12.0837V12.5003H10.8332C10.8332 11.2503 11.2082 10.7503 11.8082 10.142Z"
      />
    </g>
  </svg>
)

QuestionMarkOSVG.defaultProps = {
  size: '20',
}

QuestionMarkOSVG.propTypes = {
  size: PropTypes.string.isRequired,
}

export default QuestionMarkOSVG
