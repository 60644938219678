import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import axios, { AxiosRequestConfig } from 'axios'
import pipe from 'ramda/src/pipe'
import propEq from 'ramda/src/propEq'
import filter from 'ramda/src/filter'
import length from 'ramda/src/length'
import AddAssociatedToe from './AddAssociatedToe'
import AssociatedToesTable from './AssociatedToesTable'
import { useToastAlert } from 'components/ToastAlert'
import { User } from '../Users'
import find from 'ramda/src/find'
import './AssociatedToes.scss'

export interface CTAR {
  date_created: string
  id: number
  is_deleted: boolean
  overall_rating_numeric: string
  toe_short_name: string
}

interface AssociatedToesProps {
  customer_id: string
  customer_short_name: string
  setUsers: React.Dispatch<React.SetStateAction<User[]>>
}

function AssociatedToes({
  customer_id,
  customer_short_name,
  setUsers,
}: AssociatedToesProps) {
  const { user_id } = useParams<{ user_id?: string }>()
  const [loaded, setLoaded] = useState(false)
  const [ctars, setCtars] = useState<CTAR[]>([])
  const { setRequestErrorToast } = useToastAlert()

  useEffect(() => {
    const activeCtar = find<CTAR>(propEq('is_deleted', 'false'))(ctars)
    const activeCtarToeName = activeCtar ? activeCtar.toe_short_name : null
    const userIdNum = Number(user_id)
    setUsers((users) => {
      return users.map((u) =>
        u.user_id === userIdNum ? { ...u, toe: activeCtarToeName } : u,
      )
    })
  }, [user_id, ctars, setUsers])

  useEffect(() => {
    const fetchCTARs = async () => {
      try {
        setLoaded(false)
        const requestOptions: AxiosRequestConfig = {
          method: 'get',
          baseURL: process.env.REACT_APP_AUTH_URL,
          url: '/mcr/admin/fetch-ctars',
          params: {
            customer_id,
          },
        }
        const { data } = await axios(requestOptions)
        setCtars(data)
      } catch (e) {
        setRequestErrorToast(
          'There was a problem fetching the associated toes.',
        )
        console.log(e)
      }
      setLoaded(true)
    }
    fetchCTARs()
  }, [customer_id, setRequestErrorToast])

  const activeCount = pipe(
    filter(propEq('is_deleted', false)),
    (ctars: CTAR[]) => length(ctars),
  )(ctars)

  return (
    <div className="associated-toe-container">
      <h2>Associated Toe</h2>
      {activeCount > 1 && (
        <div className="multi-toe-warning">
          WARNING: When a customer has more than 1 active toe, functionality is
          unpredictable.
        </div>
      )}
      <AssociatedToesTable loaded={loaded} ctars={ctars} setCtars={setCtars} />
      <AddAssociatedToe
        customer_id={customer_id}
        customer_short_name={customer_short_name}
        setCtars={setCtars}
      />
    </div>
  )
}

export default AssociatedToes
