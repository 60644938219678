import axios, { AxiosRequestConfig } from 'axios'
import { useToastAlert } from 'components/ToastAlert'
import {
  Button,
  Checkbox,
  RadioInput,
  TextInput,
  TextInputType,
} from 'components/common'
import { useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import './AddAccessCodes.scss'
import { ChevronRightSVG } from 'assets/svg'

function AddAccessCodes() {
  const history = useHistory()
  const { partner_id, batch_id } = useParams<{
    partner_id?: string
    batch_id?: string
  }>()
  const [loading, setLoading] = useState(false)
  const [expires_in, setExpiresIn] = useState(-1)
  const [count, setCount] = useState('')
  const [singleCode, setSingleCode] = useState(false)
  const { setRequestErrorToast } = useToastAlert()

  const handleSubmit = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault()

    const requestOptions: AxiosRequestConfig = {
      method: 'post',
      baseURL: process.env.REACT_APP_AUTH_URL,
      url: `/mcr/admin/batch/${batch_id}/add-access-codes`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        expires_in,
        count: Number(count),
        partner_id: Number(partner_id),
        single_code: singleCode,
      }),
    }

    try {
      setLoading(true)
      await axios(requestOptions)
      setLoading(false)
      history.push(`/partners/${partner_id}/batch/${batch_id}`)
    } catch (e) {
      setRequestErrorToast('An error was encountered while creating the batch.')
      setLoading(false)
    }
  }

  return (
    <div id="partner-details-container">
      <div className="header-container">
        <Link to={`/partners/${partner_id}/batch/${batch_id}`} className="back">
          <ChevronRightSVG size="24" />
          Back
        </Link>
        <h1>Add Access Codes</h1>
      </div>
      <form className="create-batch-form" onSubmit={handleSubmit}>
        <TextInput
          value={count}
          label="Number of Codes"
          type={TextInputType.NumberType}
          placeholder="100"
          onChange={({ target: { value } }) => setCount(value)}
          fullBorder
        />
        <fieldset>
          <legend>Expires In:</legend>
          <RadioInput
            checked={expires_in === -1}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setExpiresIn(Number(e.target.value))
            }
            value={-1}
            name="expires_in"
            id="never"
            label="never"
          />
          <RadioInput
            checked={expires_in === 365}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setExpiresIn(Number(e.target.value))
            }
            value={365}
            name="expires_in"
            id="1 year"
            label="1 year"
          />
          <RadioInput
            checked={expires_in === 730}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setExpiresIn(Number(e.target.value))
            }
            value={730}
            name="expires_in"
            id="2 years"
            label="2 years"
          />
        </fieldset>

        <Checkbox
          id="single-code"
          checked={singleCode}
          onChange={() => setSingleCode((val) => !val)}
          label="Single Code"
        />

        <Button type="submit" loading={loading}>
          Submit
        </Button>
      </form>
    </div>
  )
}
// test
export default AddAccessCodes
