import React from 'react'
import PropTypes from 'prop-types'

const DataSearchSVG = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.5 4H3.5C2.95 4 2.5 4.45 2.5 5V11C2.5 11.55 2.95 12 3.5 12H20.5C21.05 12 21.5 11.55 21.5 11V5C21.5 4.45 21.05 4 20.5 4ZM19.5 10V6H4.5V10H19.5ZM2.5 14H21.5V16H2.5V14ZM2.5 18H21.5V20H2.5V18Z"
    />
  </svg>
)

DataSearchSVG.defaultProps = {
  size: '24',
}

DataSearchSVG.propTypes = {
  size: PropTypes.string.isRequired,
}

export default DataSearchSVG
