import React from 'react'
import PropTypes from 'prop-types'

const ExclamationCircleOSVG = ({ size, className }) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 8C0 3.584 3.576 0 7.992 0C12.416 0 16 3.584 16 8C16 12.416 12.416 16 7.992 16C3.576 16 0 12.416 0 8ZM8.80002 4.8C8.80002 4.36 8.44002 4 8.00002 4C7.56002 4 7.20002 4.36 7.20002 4.8V8C7.20002 8.44 7.56002 8.8 8.00002 8.8C8.44002 8.8 8.80002 8.44 8.80002 8V4.8ZM8.00003 14.4C4.46403 14.4 1.60003 11.536 1.60003 8C1.60003 4.464 4.46403 1.6 8.00003 1.6C11.536 1.6 14.4 4.464 14.4 8C14.4 11.536 11.536 14.4 8.00003 14.4ZM7.20002 10.4V12H8.80002V10.4H7.20002Z"
      fill="#EB5757"
    />
  </svg>
)

ExclamationCircleOSVG.defaultProps = {
  size: '16',
}

ExclamationCircleOSVG.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string.isRequired,
}

export default ExclamationCircleOSVG
