import { useToastAlert } from 'components/ToastAlert'
import { useEffect, useState, useRef } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import axios, { AxiosRequestConfig } from 'axios'
import { format, parseISO } from 'date-fns'
import {
  Loading,
  Table,
  TableBody,
  TableHead,
  TableCellHeader,
  TableRow,
  TableCellData,
  Pagination,
  Button,
  IconButton,
} from 'components/common'
import type { TableConfig } from '../Partners'
import '../UsersTable.scss'
import { ColorTypes } from 'components/common/buttons/IconButton'
import { AddCircleOSVG } from 'assets/svg'

export interface AccessCodeBatch {
  id: number
  batch: number
  created_by: string
  note: string
  shared_at: string
  inserted_at: string
  updated_at: string
}

const tableHeaders = [
  { label: 'Batch ID', id: 'id' },
  { label: 'Batch', id: 'batch' },
  { label: 'Created By', id: 'created_by' },
  { label: 'Shared At', id: 'shared_at' },
  { label: 'Inserted At', id: 'inserted_at' },
  { label: 'Updated At', id: 'updated_at' },
  { label: 'Note', id: 'note' },
]

function AccessCodeBatches() {
  const history = useHistory()
  const { partner_id } = useParams<{ partner_id?: string }>()
  const tableContainerEl = useRef<HTMLDivElement>(null)
  const { setRequestErrorToast } = useToastAlert()
  const [loaded, setLoaded] = useState<boolean>(false)
  const [batches, setBatches] = useState<AccessCodeBatch[]>([])
  const [tableConfig, setTableConfig] = useState<TableConfig>({
    method: 'asc_nulls_last',
    field: 'batch',
    activePage: 1,
    totalPages: 1,
  })

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        if (tableContainerEl !== null && tableContainerEl.current !== null) {
          tableContainerEl.current.scrollTo(0, 0)
        }
        setLoaded(false)
        const requestOptions: AxiosRequestConfig = {
          method: 'get',
          baseURL: process.env.REACT_APP_AUTH_URL,
          url: `/mcr/admin/partner/${partner_id}/access-code-batches`,
          params: {
            mcr_partner_id: partner_id,
            method: tableConfig.method,
            field: tableConfig.field,
            page: tableConfig.activePage,
            perPage: 500,
          },
        }
        const { data } = await axios(requestOptions)
        setBatches(data.results)
        setTableConfig((prevTableConfig) => ({
          ...prevTableConfig,
          totalPages: data.page_count,
        }))
        setLoaded(true)
      } catch (e) {
        setRequestErrorToast('There was a problem fetching the partners.')
        setLoaded(true)
        console.log(e)
      }
    }
    fetchUsers()
  }, [
    tableConfig.method,
    tableConfig.field,
    tableConfig.activePage,
    partner_id,
    setLoaded,
    setBatches,
    setRequestErrorToast,
  ])

  const changeSort = (id: string): void => {
    if (id === tableConfig.field) {
      setTableConfig((prevTableConfig) =>
        prevTableConfig.method === 'asc_nulls_last'
          ? { ...prevTableConfig, method: 'desc_nulls_last' }
          : { ...prevTableConfig, method: 'asc_nulls_last' },
      )
    } else {
      setTableConfig((prevTableConfig) => ({
        ...prevTableConfig,
        method: 'asc_nulls_last',
        field: id,
      }))
    }
  }

  const changePage = (
    e: React.MouseEvent<HTMLButtonElement>,
    { activePage }: { activePage: number },
  ): void => {
    setTableConfig((prevTableConfig) => ({
      ...prevTableConfig,
      activePage: activePage,
    }))
  }

  return (
    <div className="users-table-container">
      <div className="access-code-batches-header-container">
        <h2>Access Code Batches</h2>
        <Link
          className="create-batch"
          to={`/partners/${partner_id}/create-batch`}
        >
          Create Batch
          <IconButton icon={AddCircleOSVG} color={ColorTypes.Green} />
        </Link>
      </div>
      <div className="table-container" ref={tableContainerEl}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCellHeader></TableCellHeader>
              {tableHeaders.map(({ label, id }) => (
                <TableCellHeader
                  key={id}
                  sortable
                  sorted={id === tableConfig.field}
                  sortDirection={tableConfig.method}
                  onClick={() => changeSort(id)}
                >
                  {label}
                </TableCellHeader>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {batches.map((b) => {
              return (
                <TableRow key={b.id}>
                  <TableCellData className="edit-button-cell">
                    <Button
                      color="blue outline"
                      onClick={() =>
                        history.push(`/partners/${partner_id}/batch/${b.id}`)
                      }
                    >
                      Details
                    </Button>
                  </TableCellData>
                  <TableCellData>{b.id}</TableCellData>
                  <TableCellData>{b.batch}</TableCellData>
                  <TableCellData>{b.created_by}</TableCellData>
                  <TableCellData>
                    {b.shared_at
                      ? format(
                          parseISO(b.shared_at),
                          'MMM d, yyyy hh:mm aaa zzz',
                        )
                      : 'Not shared yet'}
                  </TableCellData>
                  <TableCellData>
                    {format(
                      parseISO(b.inserted_at),
                      'MMM d, yyyy hh:mm aaa zzz',
                    )}
                  </TableCellData>
                  <TableCellData>
                    {format(
                      parseISO(b.updated_at),
                      'MMM d, yyyy hh:mm aaa zzz',
                    )}
                  </TableCellData>
                  <TableCellData>
                    <span className="wrap">{b.note}</span>
                  </TableCellData>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
        <Loading isLoading={!loaded} overlay />
      </div>
      <div className="table-controls">
        <Pagination
          activePage={tableConfig.activePage}
          onPageChange={changePage}
          totalPages={tableConfig.totalPages}
        />
      </div>
    </div>
  )
}

export default AccessCodeBatches
