import React from 'react'
import PropTypes from 'prop-types'

const AngleDown = ({ className, width, height, fill }) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 10 6"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M5.52855 5.24999L8.97105 1.80748C9.26355 1.51498 9.26355 1.04248 8.97105 0.749984C8.67855 0.457484 8.20605 0.457484 7.91355 0.749984L4.99605 3.65998L2.08606 0.749984C1.79356 0.457484 1.32106 0.457484 1.02856 0.749984C0.736055 1.04248 0.736055 1.51498 1.02856 1.80748L4.47106 5.24999C4.75605 5.54249 5.23605 5.54249 5.52855 5.24999Z" />
  </svg>
)

AngleDown.defaultProps = {
  width: '10',
  height: '6',
  className: '',
}

AngleDown.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  height: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
}

export default AngleDown
