import React from 'react'

const ChevronRightSVG = ({ size="24", ...rest }) => (
  <svg
    {...rest}
    width={size}
    height={size}
    viewBox="0 0 8 12"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1.00002 0.71051C0.61002 1.10051 0.61002 1.73051 1.00002 2.12051L4.88002 6.00051L1.00002 9.88051C0.61002 10.2705 0.61002 10.9005 1.00002 11.2905C1.39002 11.6805 2.02002 11.6805 2.41002 11.2905L7.00002 6.70051C7.39002 6.31051 7.39002 5.68051 7.00002 5.29051L2.41002 0.70051C2.03002 0.32051 1.39002 0.32051 1.00002 0.71051Z" />
  </svg>
)

export default ChevronRightSVG
