import React from 'react'
import PropTypes from 'prop-types'

const FolderMoveSVG = ({ size, className }) => (
  <svg
    className={className}
    width={size}
    height={size}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.82502 3.82501C8.50836 3.50834 8.08335 3.33334 7.64169 3.33334H3.33335C2.41669 3.33334 1.67502 4.08334 1.67502 5.00001L1.66669 15C1.66669 15.9167 2.41669 16.6667 3.33335 16.6667H16.6667C17.5834 16.6667 18.3334 15.9167 18.3334 15V6.66668C18.3334 5.75001 17.5834 5.00001 16.6667 5.00001H10L8.82502 3.82501Z"
      fill="#D5DDE4"
    />
    <path d="M6 11L13 11" stroke="white" strokeLinejoin="round" />
    <path
      d="M11 13.491L14 10.991L11 8.49097"
      stroke="white"
      strokeLinejoin="round"
    />
  </svg>
)

FolderMoveSVG.defaultProps = {
  size: '20',
}

FolderMoveSVG.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string.isRequired,
}

export default FolderMoveSVG
