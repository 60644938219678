import React from 'react'
import PropTypes from 'prop-types'

const AssociativeArrowSVG = ({ size, ...rest }) => (
  <svg
    {...rest}
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="#D5DDE4"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 7v4h13.17l-3.58-3.59L16 6l6 6-6 6-1.41-1.41L18.17 13H3V7h2z"
      fill="#D5DDE4"
    />
  </svg>
)

AssociativeArrowSVG.defaultProps = {
  size: '18',
}

AssociativeArrowSVG.propTypes = {
  size: PropTypes.string.isRequired,
}

export default AssociativeArrowSVG
