import React from 'react'
import PropTypes from 'prop-types'

const DashboardSVG = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path fill="none" d="M0 0h24v24H0V0z" />
    <path d="M19 5v2h-4V5h4M9 5v6H5V5h4m10 8v6h-4v-6h4M9 17v2H5v-2h4M21 3h-8v6h8V3zM11 3H3v10h8V3zm10 8h-8v10h8V11zm-10 4H3v6h8v-6z" />
  </svg>
)

DashboardSVG.defaultProps = {
  size: '24',
}

DashboardSVG.propTypes = {
  size: PropTypes.string.isRequired,
}

export default DashboardSVG
