import React from 'react'
import PropTypes from 'prop-types'

const RemoveCircleOSVG = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path fill="none" d="M0 0h24v24H0V0z" />
    <path d="M7 12c0 .55.45 1 1 1h8c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1zm5-10C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
  </svg>
)

RemoveCircleOSVG.defaultProps = {
  size: '24',
}

RemoveCircleOSVG.propTypes = {
  size: PropTypes.string.isRequired,
}

export default RemoveCircleOSVG
