import axios, { AxiosRequestConfig } from 'axios'
import { Button, DatePicker } from 'components/common'
import { useState } from 'react'
import './ComplianceReport.scss'

function ComplianceReport() {
  const [downloading, setDownloading] = useState(false)
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(() => {
    const now = new Date()
    return new Date(now.getFullYear(), now.getMonth() + 1, now.getDate())
  })

  const convertToUTCDate = (date: Date) => {
    return new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()),
    ).toISOString()
  }

  const handleSubmit = async () => {
    setDownloading(true)
    const requestOptions: AxiosRequestConfig<{
      startDate: string
      endDate: string
    }> = {
      method: 'get',
      baseURL: process.env.REACT_APP_AUTH_URL,
      url: `/api/compliance-csv-mcr-users`,
      params: {
        start_date: convertToUTCDate(startDate),
        end_date: convertToUTCDate(endDate),
      },
    }
    const { data } = await axios(requestOptions)
    const file = new Blob([data], { type: 'text/csv' })

    const element = document.createElement('a')
    element.href = URL.createObjectURL(file)
    element.download = `compliance_report_all_partners.csv`
    document.body.appendChild(element)
    element.click()

    setDownloading(false)
  }

  return (
    <div>
      <h4>Create Compliance Report for all Partners</h4>
      <div className="compliance-report-container">
        <label htmlFor="start-date">Start Date:</label>
        <DatePicker
          id="start-date"
          className="start-date"
          placeholderText="start date..."
          selected={startDate}
          onChange={setStartDate}
        />
        <div className="end-date-container">
          <label htmlFor="end-date">End Date:</label>
          <DatePicker
            id="end-date"
            className="end-date"
            placeholderText="end date..."
            selected={endDate}
            onChange={setEndDate}
          />
        </div>
        <Button
          color="green outline"
          className="get-report-button"
          loading={downloading}
          onClick={handleSubmit}
        >
          Get Report
        </Button>
      </div>
    </div>
  )
}

export default ComplianceReport
