import React from 'react'
import IdleTimer from 'react-idle-timer'
import type { EVENTS } from 'react-idle-timer'
import axios from 'axios'
import { useHistory } from 'react-router-dom'

const KeepAlive = () => {
  const history = useHistory()
  const IDLE_TIMEOUT = 1000 * 60 * 20 // 20 mins
  const THROTTLE_EVENT_TIME = 1000 * 60 * 5 // 5 mins
  const CAPTURED_EVENTS: EVENTS[] = [
    'keydown',
    'wheel',
    'DOMMouseScroll',
    'mousewheel',
    'mousedown',
    'touchstart',
    'touchmove',
    'MSPointerDown',
    'MSPointerMove',
    'visibilitychange',
  ]

  const ping = async () => {
    try {
      await axios.get('/session', { baseURL: process.env.REACT_APP_AUTH_URL })
    } catch (e) {
      console.log('error in ping')
      console.log(e)
    }
  }

  return (
    <IdleTimer
      element={document}
      onActive={ping}
      onAction={ping}
      onIdle={() => history.push('/')}
      timeout={IDLE_TIMEOUT}
      events={CAPTURED_EVENTS}
      throttle={THROTTLE_EVENT_TIME}
    />
  )
}

export default KeepAlive
