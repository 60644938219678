import React, {
  useEffect,
  useRef,
  useState,
  createContext,
  useMemo,
  useContext,
} from 'react'
import IconButton, { ColorTypes } from 'components/common/buttons/IconButton'
import { CloseSVG } from 'assets/svg'
import './ToastAlert.scss'

export enum InfoType {
  Primary = 'primary',
  Success = 'success',
  Warning = 'warning',
  Danger = 'danger',
}

interface ToastAlertConfig {
  infoType?: InfoType
  title?: string
  message?: string
  showToast: boolean
  persistent?: boolean
  dangerous?: string
}

interface ToastAlertContextInterface {
  setToast: (toastAlert: ToastAlertConfig) => void
  setRequestErrorToast: (message: string) => void
}

const ToastAlertContext = createContext<ToastAlertContextInterface>({
  setToast: () => null,
  setRequestErrorToast: () => null,
})

export const useToastAlert = () => useContext(ToastAlertContext)

const ToastAlert = ({ children }: { children: React.ReactNode }) => {
  const autoDismiss = useRef<NodeJS.Timeout>()
  const [
    { infoType, title, message, showToast, persistent, dangerous },
    setToastAlertProps,
  ] = useState<ToastAlertConfig>({ showToast: false })

  useEffect(() => {
    if (showToast && !persistent) {
      autoDismiss.current = setTimeout(
        () => setToastAlertProps({ showToast: false }),
        7000,
      )
    }
    return () => autoDismiss.current && clearTimeout(autoDismiss.current)
  }, [showToast, persistent])

  const setRequestErrorToast = (message: string) =>
    setToastAlertProps({
      showToast: true,
      infoType: InfoType.Danger,
      title: 'Error: Problem with Request.',
      message,
    })

  const toastFuncs = useMemo(() => {
    return {
      setToast: setToastAlertProps,
      setRequestErrorToast,
    }
  }, [setToastAlertProps])

  const renderToast = () => {
    if (!showToast) return null
    return (
      <div className="toast-container toast-width-2">
        <div className={`toast-alert-v2 ${infoType}`} data-testid="v3-toast-v2">
          <IconButton
            className="toast-alert-v2-close-button"
            icon={CloseSVG}
            onClick={() => setToastAlertProps({ showToast: false })}
            color={ColorTypes.DarkBlue}
          />
          <div className="toast-alert-v2-title">{title}</div>
          {dangerous ? (
            <div
              className="toast-alert-v2-message"
              dangerouslySetInnerHTML={{ __html: dangerous }}
            />
          ) : (
            <div className="toast-alert-v2-message">{message}</div>
          )}
        </div>
      </div>
    )
  }

  return (
    <ToastAlertContext.Provider value={toastFuncs}>
      {children}
      {renderToast()}
    </ToastAlertContext.Provider>
  )
}

export default ToastAlert
