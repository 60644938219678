import React from 'react'
import PropTypes from 'prop-types'

const ChevronUpSVG = ({ size, className }) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8.12 14.71L12 10.83l3.88 3.88a.996.996 0 101.41-1.41L12.7 8.71a.996.996 0 00-1.41 0L6.7 13.3a.996.996 0 000 1.41c.39.38 1.03.39 1.42 0z" />
  </svg>
)

ChevronUpSVG.defaultProps = {
  size: '24',
}

ChevronUpSVG.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string.isRequired,
}

export default ChevronUpSVG
