import React from 'react'
import PropTypes from 'prop-types'

const BulletSVG = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 8 8"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="4" cy="4" r="3.5" />
  </svg>
)

BulletSVG.defaultProps = {
  size: '8',
}

BulletSVG.propTypes = {
  size: PropTypes.string.isRequired,
}

export default BulletSVG
