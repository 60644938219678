import { Switch, Route } from 'react-router-dom'
import PartnersTable from './PartnersTable'
import PartnerDetails from './PartnerDetails/PartnerDetails'
import BatchDetails from './PartnerDetails/BatchDetails/BatchDetails'
import CreateBatch from './PartnerDetails/CreateBatch'
import AddAccessCodes from './PartnerDetails/BatchDetails/AddAccessCodes'
import CreatePartner from './CreatePartner'

export interface Partner {
  partner_id: number
  partner_name: string
  contact_name: string | null
  contact_email: string | null
  inserted_at: string
}
export interface TableConfig {
  method: 'asc_nulls_last' | 'desc_nulls_last'
  field: string
  activePage: number
  totalPages: number
}

function Partners() {
  return (
    <Switch>
      <Route exact path="/partners" component={PartnersTable} />
      <Route exact path="/partners/create-partner" component={CreatePartner} />
      <Route
        path="/partners/:partner_id/create-batch"
        component={CreateBatch}
      />
      <Route
        path="/partners/:partner_id/batch/:batch_id/add-access-codes"
        component={AddAccessCodes}
      />
      <Route
        path="/partners/:partner_id/batch/:batch_id"
        component={BatchDetails}
      />
      <Route path="/partners/:partner_id" component={PartnerDetails} />
    </Switch>
  )
}

export default Partners
