import React, { useState } from 'react'
import classnames from 'classnames'
import './IconButton.scss'

/**
 * Use `IconButton` when you want button functionality with an svg icon. Use the hoverIcon prop to toggle (i.e. a 'solid' and 'outline' svg icon) on hover.
 */

enum ButtonTypes {
  Button = 'button',
  Submit = 'submit',
  Reset = 'reset',
}

export enum ColorTypes {
  Green = 'green',
  Red = 'red',
  Blue = 'blue',
  DarkBlue = 'dark-blue',
  Dark = 'dark',
}

interface IconButtonProps {
  className?: string
  color?: ColorTypes
  disabled?: boolean
  hoverIcon?: () => React.ReactElement
  icon: () => React.ReactElement
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  type?: ButtonTypes
}

const IconButton = ({
  onClick,
  type,
  disabled = false,
  color,
  icon: Icon,
  hoverIcon: HoverIcon,
  className,
  ...rest
}: IconButtonProps) => {
  const [isHovered, setIsHovered] = useState(false)
  if (!HoverIcon) {
    return (
      <button
        type={type}
        onClick={onClick}
        disabled={disabled}
        className={classnames('v3-icon-button', color, className)}
        {...rest}
      >
        <Icon />
      </button>
    )
  }
  return (
    <button
      type={type}
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      disabled={disabled}
      className={classnames('v3-icon-button', {
        color,
        className,
      })}
      {...rest}
    >
      {!!HoverIcon && isHovered && !disabled ? <HoverIcon /> : <Icon />}
    </button>
  )
}

export default IconButton
