import React from 'react'
import PropTypes from 'prop-types'

const SupplyChainSVG = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="icon/action/view_day_24px" opacity="0.5">
      <path
        id="Rectangle 92 (Stroke)"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 10H2V14H6V10ZM1 8C0.447715 8 0 8.44772 0 9V15C0 15.5523 0.447715 16 1 16H7C7.55228 16 8 15.5523 8 15V9C8 8.44772 7.55228 8 7 8H1Z"
        fill="#435A70"
      />
      <path
        id="Rectangle 93 (Stroke)"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 4H18V8H22V4ZM17 2C16.4477 2 16 2.44772 16 3V9C16 9.55228 16.4477 10 17 10H23C23.5523 10 24 9.55228 24 9V3C24 2.44772 23.5523 2 23 2H17Z"
        fill="#435A70"
      />
      <path
        id="Rectangle 94 (Stroke)"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 16H18V20H22V16ZM17 14C16.4477 14 16 14.4477 16 15V21C16 21.5523 16.4477 22 17 22H23C23.5523 22 24 21.5523 24 21V15C24 14.4477 23.5523 14 23 14H17Z"
        fill="#435A70"
      />
      <path
        id="Union"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 6C11 5.44772 11.4477 5 12 5H13H16V7L13 7V17H16V19H13H12C11.4477 19 11 18.5523 11 18V13H8V11H11L11 6Z"
        fill="#435A70"
      />
    </g>
  </svg>
)

SupplyChainSVG.defaultProps = {
  size: '24',
}

SupplyChainSVG.propTypes = {
  size: PropTypes.string,
}

export default SupplyChainSVG
