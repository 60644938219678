import React from 'react'
import ReactDOM from 'react-dom'
import axios from 'axios'
import App from './App'
import { Router } from 'react-router-dom'
import history from './history'
import './index.css'
// import reportWebVitals from './reportWebVitals'

axios.defaults.baseURL = process.env.REACT_APP_API_URL
axios.defaults.withCredentials = true
axios.interceptors.response.use(
  (resp) => resp,
  (error) => {
    if (error?.response?.status === 401) {
      history.push('/login')
    }
    return Promise.reject(error)
  },
)

ReactDOM.render(
  <Router history={history}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Router>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
