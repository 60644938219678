import axios, { AxiosRequestConfig } from 'axios'
import { useParams, Redirect } from 'react-router-dom'
import { User } from '../Users'
import { format, parseISO } from 'date-fns'
import { Link } from 'react-router-dom'
import { Loading, Toggle } from 'components/common'
import { ChevronRightSVG } from 'assets/svg'
import AssociatedToes from './AssociatedToes'
import { useToastAlert } from 'components/ToastAlert'
import './UserDetails.scss'

interface UserDetailsProps {
  users: User[]
  loaded: boolean
  setUsers: React.Dispatch<React.SetStateAction<User[]>>
}

function UserDetails({ users, loaded, setUsers }: UserDetailsProps) {
  const { user_id } = useParams<{ user_id?: string }>()
  const { setRequestErrorToast } = useToastAlert()
  const user_id_as_number = Number(user_id)
  const user = users.find((u: User): boolean => u.user_id === user_id_as_number)

  if (!loaded) {
    return <Loading isLoading overlay />
  }

  if (user === undefined) {
    return <Redirect to="/users" />
  }

  const toggleActive = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const is_active = e.target.checked
    setUsers((users) =>
      users.map((u) =>
        u.user_id === user_id_as_number ? { ...u, is_active } : u,
      ),
    )
    try {
      const requestOptions: AxiosRequestConfig = {
        method: 'put',
        baseURL: process.env.REACT_APP_AUTH_URL,
        url: '/mcr/admin/update-user-active-status',
        params: {
          id: user_id,
          is_active: e.target.checked,
        },
      }
      await axios(requestOptions)
    } catch (e) {
      setRequestErrorToast('There was a problem updating the user.')
      setUsers((users) =>
        users.map((u) =>
          u.user_id === user_id_as_number ? { ...u, is_active: !is_active } : u,
        ),
      )
    }
  }

  return (
    <div id="user-details-container">
      <div className="header-container">
        <Link to="/users" className="back">
          <ChevronRightSVG size="24" />
          Back
        </Link>
        <h1>User Details</h1>
      </div>
      <h2>Info</h2>
      <table className="user-details">
        <tr>
          <td className="label">Active?:</td>
          <td className="data">
            <Toggle
              checked={user?.is_active}
              onChange={toggleActive}
              id="is_active_toggle"
            />
          </td>
        </tr>
        <tr>
          <td className="label">User Id:</td>
          <td className="data">{user.user_id}</td>
        </tr>
        <tr>
          <td className="label">First Name:</td>
          <td className="data">{user.first_name}</td>
        </tr>
        <tr>
          <td className="label">Last Name:</td>
          <td className="data">{user.last_name}</td>
        </tr>
        <tr>
          <td className="label">Email:</td>
          <td className="data">
            <a href={`mailto:${user.email}`}>{user.email}</a>
          </td>
        </tr>
        <tr>
          <td className="label">Last Sign In At:</td>
          <td className="data">
            {user.last_sign_in_at
              ? format(
                  parseISO(`${user.last_sign_in_at}+00:00`),
                  'MMM d, yyyy hh:mm aaa zzz',
                )
              : `Hasn't signed in`}
          </td>
        </tr>
        <tr>
          <td className="label">SSO Provider:</td>
          <td className="data">{user.sso_provider}</td>
        </tr>
        <tr>
          <td className="label">SSO ID:</td>
          <td className="data">{user.sso_uid}</td>
        </tr>
        <tr>
          <td className="label">Customer ID:</td>
          <td className="data">{user.customer_id}</td>
        </tr>
        <tr>
          <td className="label">Customer Name:</td>
          <td className="data">{user.customer}</td>
        </tr>
        <tr>
          <td className="label">Login URI:</td>
          <td className="data">
            <a href={user.login_uri} target="_blank" rel="noreferrer">
              {user.login_uri}
            </a>
          </td>
        </tr>
      </table>
      {user.customer_id && (
        <AssociatedToes
          customer_id={user.customer_id}
          customer_short_name={user.customer}
          setUsers={setUsers}
        />
      )}
    </div>
  )
}

export default UserDetails
