import React from 'react'
import PropTypes from 'prop-types'

const CircleOSVG = ({ size, className }) => (
  <svg
    stroke="#577592"
    className={className}
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="10" cy="10" r="8" fill="none" />
  </svg>
)

CircleOSVG.defaultProps = {
  size: '24',
}

CircleOSVG.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string.isRequired,
}

export default CircleOSVG
