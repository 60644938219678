import React from 'react'
import PropTypes from 'prop-types'

const ProgressReportDataIconSVG = ({ fill }) => (
  <svg
    width="22px"
    height="12px"
    viewBox="0 0 22 12"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Group-307"
        transform="translate(-11.000000, -16.000000)"
        fill={fill}
      >
        <path
          d="M16.1667,18.5 L32.5,18.5 L32.5,16.1666 L16.1667,16.1666 L16.1667,18.5 Z M16.1667,23.1666 L32.5,23.1666 L32.5,20.8333 L16.1667,20.8333 L16.1667,23.1666 Z M32.5,27.8333 L16.1667,27.8333 L16.1667,25.5 L32.5,25.5 L32.5,27.8333 Z M11.5,27.8333 L13.8333,27.8333 L13.8333,25.5 L11.5,25.5 L11.5,27.8333 Z M13.8333,16.1666 L13.8333,18.5 L11.5,18.5 L11.5,16.1666 L13.8333,16.1666 Z M11.5,23.1666 L13.8333,23.1666 L13.8333,20.8333 L11.5,20.8333 L11.5,23.1666 Z"
          id="Shape"
        ></path>
      </g>
    </g>
  </svg>
)

ProgressReportDataIconSVG.defaultProps = {
  fill: '#173A56',
}

ProgressReportDataIconSVG.propTypes = {
  fill: PropTypes.string,
  size: PropTypes.string.isRequired,
}

export default ProgressReportDataIconSVG
