import React from 'react'
import PropTypes from 'prop-types'

const MyCompanySmallSVG = ({ fillOutside, fillInside }) => (
  <svg
    width="12px"
    height="15px"
    viewBox="0 0 12 15"
    className="my-company-small-svg"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g fillRule="nonzero">
        <path
          d="M10.45376,0 L1.30672,0 C0.5880248,0 0.006533608,0.5880248 0.006533608,1.30672 L0,9.75464 C0,10.20552 0.228676,10.60408 0.5749576,10.83928 L5.520896,14.13216 C5.74304,14.27592 6.023984,14.27592 6.246128,14.13216 L11.18552,10.83928 C11.53184,10.60408 11.76048,10.20552 11.76048,9.75464 L11.76048,1.30672 C11.76048,0.5880248 11.17248,0 10.45376,0 Z"
          fill="#2F80ED"
        ></path>
        <path
          d="M4.898864,9.404 L4.898864,6.953888 L6.858944,6.953888 L6.858944,9.404 C6.858944,9.67352 7.079456,9.894 7.34896,9.894 L8.81904,9.894 C9.08856,9.894 9.30904,9.67352 9.30904,9.404 L9.30904,5.973848 L10.14208,5.973848 C10.36752,5.973848 10.47528,5.694536 10.30376,5.547528 L6.207216,1.857672 C6.021008,1.691064 5.7368,1.691064 5.550592,1.857672 L1.454016,5.547528 C1.287408,5.694536 1.390312,5.973848 1.61572,5.973848 L2.44876,5.973848 L2.44876,9.404 C2.44876,9.67352 2.669264,9.894 2.938776,9.894 L4.40884,9.894 C4.678352,9.894 4.898864,9.67352 4.898864,9.404 Z"
          fill="#FFFFFF"
        ></path>
      </g>
    </g>
  </svg>
)

MyCompanySmallSVG.defaultProps = {
  fillOutside: '#2F80ED',
  fillInside: '#FFF',
}

MyCompanySmallSVG.propTypes = {
  fillInside: PropTypes.string.isRequired,
  fillOutside: PropTypes.string.isRequired,
}

export default MyCompanySmallSVG
