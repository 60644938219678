import React from 'react'
import PropTypes from 'prop-types'

const KeySVG = ({ size, className, ...rest }) => (
  <svg
    className={className}
    width={size * 22}
    height={size * 12}
    viewBox="0 0 22 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.65 4C10.83 1.67 8.61 0 6 0C2.69 0 0 2.69 0 6C0 9.31 2.69 12 6 12C8.61 12 10.83 10.33 11.65 8H16V12H20V8H22V4H11.65ZM6 8C4.9 8 4 7.1 4 6C4 4.9 4.9 4 6 4C7.1 4 8 4.9 8 6C8 7.1 7.1 8 6 8Z"
      fill="#F2C94C"
    />
  </svg>
)

KeySVG.defaultProps = {
  size: '1',
  className: '',
}

KeySVG.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string.isRequired,
}

export default KeySVG
