import { useState } from 'react'
import axios, { AxiosRequestConfig } from 'axios'
import { useHistory } from 'react-router-dom'
import { Container, TextInput, Button, TextInputType } from 'components/common'
import { useToastAlert } from './ToastAlert'
import './Login.scss'

function Login() {
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const { setRequestErrorToast } = useToastAlert()

  const handleSubmit = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault()

    const data = {
      username: email,
      password: password,
    }

    const requestOptions: AxiosRequestConfig = {
      method: 'post',
      baseURL: process.env.REACT_APP_AUTH_URL,
      url: '/oauth/login-cookie',
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(data),
    }

    try {
      setLoading(true)
      await axios(requestOptions)
      setLoading(false)
      history.push('/')
    } catch (e) {
      setRequestErrorToast('An error was encountered while signing in.')
      setLoading(false)
    }
  }

  return (
    <div id="login-container">
      <Container>
        <h1>Login</h1>
        <form onSubmit={handleSubmit}>
          <TextInput
            value={email}
            label="Email"
            type={TextInputType.EmailType}
            placeholder="joe@example.com"
            onChange={({ target: { value } }) => setEmail(value)}
            fullBorder
          />
          <TextInput
            value={password}
            label="Password"
            type={TextInputType.PasswordType}
            placeholder="*********"
            onChange={({ target: { value } }) => setPassword(value)}
            fullBorder
          />
          <Button type="submit" loading={loading}>
            Login
          </Button>
        </form>
      </Container>
    </div>
  )
}

export default Login
