import { useState } from 'react'
import axios, { AxiosRequestConfig } from 'axios'
import { format } from 'date-fns'
import {
  Button,
  Table,
  TableHead,
  TableRow,
  TableCellHeader,
  TableBody,
  TableCellData,
} from 'components/common'
import { TOE } from './AddAssociatedToe'
import { CTAR } from './AssociatedToes'
import { useToastAlert } from 'components/ToastAlert'
import './ToeSearchResultsTable.scss'

interface ToeSearchResultsTableProps {
  toes: TOE[]
  setCtars: React.Dispatch<React.SetStateAction<CTAR[]>>
  customer_id: string
  customer_short_name: string
}

function ToeSearchResultsTable({
  toes,
  setCtars,
  customer_id,
  customer_short_name,
}: ToeSearchResultsTableProps) {
  const [loadingId, setLoadingId] = useState<string | null>(null)
  const { setRequestErrorToast } = useToastAlert()

  if (!toes.length) return null

  const associateToe = async (toe_id: string, customer_id: string) => {
    try {
      setLoadingId(toe_id)
      const requestOptions: AxiosRequestConfig = {
        method: 'post',
        baseURL: process.env.REACT_APP_AUTH_URL,
        url: '/mcr/admin/associate-toe-with-customer',
        data: {
          toe_id,
          customer_id,
          customer_short_name,
        },
      }
      const { data } = await axios(requestOptions)
      setCtars((ctars) => [...ctars, data])
    } catch (e) {
      setRequestErrorToast('There was a problem associating the toe.')
      console.log(e)
    }
    setLoadingId(null)
  }

  return (
    <div className="search-results-container">
      <Table>
        <TableHead>
          <TableRow>
            <TableCellHeader>Name</TableCellHeader>
            <TableCellHeader>Id</TableCellHeader>
            <TableCellHeader>Last Updated</TableCellHeader>
            <TableCellHeader></TableCellHeader>
          </TableRow>
        </TableHead>
        <TableBody className="toe-table-body">
          {toes.map((toe: TOE) => {
            return (
              <TableRow className="toe-item" key={toe.id}>
                <TableCellData>{toe.name}</TableCellData>
                <TableCellData>{toe.id}</TableCellData>
                <TableCellData>
                  {format(new Date(toe.updated_at), 'MMM d, yyyy hh:mm aaa')}
                </TableCellData>
                <TableCellData>
                  <Button
                    color="green outline"
                    onClick={() => associateToe(toe.id, customer_id)}
                    loading={loadingId === toe.id}
                  >
                    Add Toe
                  </Button>
                </TableCellData>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </div>
  )
}

export default ToeSearchResultsTable
