import React, { useEffect, useRef } from 'react'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import LoadingSVG from 'assets/svg/LoadingSVG'
import classnames from 'classnames'
import './Loading.scss'
/**
 *  The Loading with overlay relies on a parent element having a `position: relative` to determine size of overlay. The overlay does not have border-radius so it may need some added CSS when in a container with border-radius. If it is the direct descendent of a Container, the appropriate border-radius should be added.
 */

interface LoadingProps {
  size?: 'small' | 'medium' | 'large'
  isLoading?: boolean
  overlay?: boolean
  className?: string
  refresh?: boolean
  children?: React.ReactNode
  loadingText?: string
}

const Loading = ({
  size = 'medium',
  isLoading = false,
  overlay = false,
  className,
  refresh,
  children,
  loadingText = 'Loading',
}: LoadingProps) => {
  const overlayEl = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const overlayElement = overlayEl.current
    if (isLoading && className === 'global-loader' && overlayElement !== null) {
      disableBodyScroll(overlayElement)
    }
    return () => {
      if (overlayElement !== null) {
        enableBodyScroll(overlayElement)
      }
    }
  }, [isLoading, className])

  if (!isLoading) return null

  if (refresh) {
    return (
      <div className={classnames('v3-refresh-loading', className)}>
        <LoadingSVG size="15" />
        <span>{children}</span>
      </div>
    )
  }

  const computedSize = (() => {
    switch (size) {
      case 'small':
        return '25'
      case 'large':
        return '75'
      default:
        return '50'
    }
  })()

  return (
    <div
      className={classnames('v3-loading', size, className, {
        'with-overlay': overlay,
      })}
    >
      <div ref={overlayEl} className="overlay">
        <LoadingSVG size={computedSize} />
        {loadingText && <div className="loading-text">{loadingText}</div>}
      </div>
    </div>
  )
}

export default Loading
