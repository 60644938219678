import axios, { AxiosRequestConfig } from 'axios'
import { useState, useEffect, useRef } from 'react'
import { useToastAlert } from 'components/ToastAlert'
import { format, parseISO } from 'date-fns'
import { Link } from 'react-router-dom'
import {
  Loading,
  Table,
  TableBody,
  TableHead,
  TableCellHeader,
  TableRow,
  TableCellData,
  Pagination,
  Button,
} from 'components/common'
import type { TableConfig, Partner } from './Partners'
import './UsersTable.scss'

const tableHeaders = [
  { label: 'Partner ID', id: 'id', key: 'partner_id' },
  { label: 'Partner Name', id: 'partner_name', key: 'partner_name' },
  { label: 'Contact Name', id: 'contact_name', key: 'contact_name' },
  { label: 'Contact Email', id: 'contact_email', key: 'contact_email' },
  { label: 'Inserted At', id: 'inserted_at', key: 'inserted_at' },
]

const UsersTable = () => {
  const tableContainerEl = useRef<HTMLDivElement>(null)
  const [loaded, setLoaded] = useState<boolean>(false)
  const [partners, setPartners] = useState<Partner[]>([])
  const [tableConfig, setTableConfig] = useState<TableConfig>({
    method: 'asc_nulls_last',
    field: 'partner_name',
    activePage: 1,
    totalPages: 1,
  })
  const { setRequestErrorToast } = useToastAlert()

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        if (tableContainerEl !== null && tableContainerEl.current !== null) {
          tableContainerEl.current.scrollTo(0, 0)
        }
        setLoaded(false)
        const requestOptions: AxiosRequestConfig = {
          method: 'get',
          baseURL: process.env.REACT_APP_AUTH_URL,
          url: '/mcr/admin/partners',
          params: {
            method: tableConfig.method,
            field: tableConfig.field,
            page: tableConfig.activePage,
            perPage: 500,
          },
        }
        const { data } = await axios(requestOptions)
        setPartners(data.results)
        setTableConfig((prevTableConfig) => ({
          ...prevTableConfig,
          totalPages: data.page_count,
        }))
        setLoaded(true)
      } catch (e) {
        setRequestErrorToast('There was a problem fetching the partners.')
        setLoaded(true)
        console.log(e)
      }
    }
    fetchUsers()
  }, [
    tableConfig.method,
    tableConfig.field,
    tableConfig.activePage,
    setLoaded,
    setPartners,
    setRequestErrorToast,
  ])

  const changeSort = (id: string): void => {
    if (id === tableConfig.field) {
      setTableConfig((prevTableConfig) =>
        prevTableConfig.method === 'asc_nulls_last'
          ? { ...prevTableConfig, method: 'desc_nulls_last' }
          : { ...prevTableConfig, method: 'asc_nulls_last' },
      )
    } else {
      setTableConfig((prevTableConfig) => ({
        ...prevTableConfig,
        method: 'asc_nulls_last',
        field: id,
      }))
    }
  }

  const changePage = (
    e: React.MouseEvent<HTMLButtonElement>,
    { activePage }: { activePage: number },
  ): void => {
    setTableConfig((prevTableConfig) => ({
      ...prevTableConfig,
      activePage: activePage,
    }))
  }

  return (
    <div className="users-table-container">
      <div className="users-table-header">
        <h1>Partners</h1>
        <div className="dl-csv-button-container">
          <Link className="create-partner" to={`/partners/create-partner`}>
            <Button color="green outline">Create Partner</Button>
          </Link>
        </div>
      </div>
      <div className="table-container" ref={tableContainerEl}>
        <Table>
          <TableHead>
            <TableRow>
              {tableHeaders.map(({ label, id }) => (
                <TableCellHeader
                  key={id}
                  sortable
                  sorted={id === tableConfig.field}
                  sortDirection={tableConfig.method}
                  onClick={() => changeSort(id)}
                >
                  {label}
                </TableCellHeader>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {partners.map((u) => {
              return (
                <TableRow key={u.partner_id}>
                  {/* <TableCellData className="edit-button-cell">
                      <Button
                        color="green outline"
                        onClick={() => history.push(`users/${u.partner_id}`)}
                      >
                        Edit
                      </Button>
                    </TableCellData> */}
                  <TableCellData>{u.partner_id}</TableCellData>
                  <TableCellData>
                    <Link to={`/partners/${u.partner_id}`}>
                      {u.partner_name}
                    </Link>
                  </TableCellData>
                  <TableCellData>{u.contact_name}</TableCellData>
                  <TableCellData>{u.contact_email}</TableCellData>
                  <TableCellData>
                    {format(
                      parseISO(`${u.inserted_at}+00:00`),
                      'MMM d, yyyy hh:mm aaa zzz',
                    )}
                  </TableCellData>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
        <Loading isLoading={!loaded} overlay />
      </div>
      <div className="table-controls">
        <Pagination
          activePage={tableConfig.activePage}
          onPageChange={changePage}
          totalPages={tableConfig.totalPages}
        />
      </div>
    </div>
  )
}

export default UsersTable
