import { ChevronRightSVG } from 'assets/svg'
import { Link } from 'react-router-dom'
import PartnerInfo from './PartnerInfo'
import './PartnerDetails.scss'
import AccessCodeBatches from './AccessCodeBatches'
import ComplianceReport from './ComplianceReport'
import ActiveLicensesReport from './ActiveLicensesReport'

const PartnerDetails = () => {
  return (
    <div id="partner-details-container">
      <div className="header-container">
        <Link to="/partners" className="back">
          <ChevronRightSVG size="24" />
          Back
        </Link>
        <h1>Partner Details</h1>
      </div>
      <PartnerInfo />
      <div className="reports-grid">
        <ComplianceReport />
        <ActiveLicensesReport />
      </div>
      <AccessCodeBatches />
    </div>
  )
}

export default PartnerDetails
