import { useToastAlert } from 'components/ToastAlert'
import { parseISO, format } from 'date-fns'
import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { AccessCodeBatch } from '../AccessCodeBatches'
import axios, { AxiosRequestConfig } from 'axios'
import { Button } from 'components/common'

interface IBatchInfo extends AccessCodeBatch {
  partner: string
}

function BatchInfo() {
  const history = useHistory()
  const { batch_id } = useParams<{ batch_id?: string }>()
  const { setRequestErrorToast } = useToastAlert()
  const [loaded, setLoaded] = useState<boolean>(false)
  const [settingSharedAt, setSettingSharedAt] = useState<boolean>(false)
  const [batch, setBatch] = useState<IBatchInfo | null>(null)

  useEffect(() => {
    const fetchPartnerInfo = async () => {
      try {
        setLoaded(false)
        const requestOptions: AxiosRequestConfig = {
          method: 'get',
          baseURL: process.env.REACT_APP_AUTH_URL,
          url: `/mcr/admin/batch/${batch_id}`,
        }
        const { data } = await axios(requestOptions)
        setBatch(data)
        setLoaded(true)
      } catch (e) {
        setRequestErrorToast('There was a problem fetching batch info.')
        setLoaded(true)
        console.log(e)
      }
    }
    fetchPartnerInfo()
  }, [batch_id, setRequestErrorToast])

  const setSharedAt = async () => {
    try {
      setSettingSharedAt(true)
      const requestOptions: AxiosRequestConfig = {
        method: 'put',
        baseURL: process.env.REACT_APP_AUTH_URL,
        url: `/mcr/admin/batch/${batch_id}/update-shared-at`,
      }
      await axios(requestOptions)
      history.go(0)

      setSettingSharedAt(false)
    } catch (e) {
      setRequestErrorToast('There was a problem setting shared_at.')
      setSettingSharedAt(false)
      console.log(e)
    }
  }

  if (!loaded) return <div>Loading...</div>

  return (
    <div className="batch-details">
      <h2>Info</h2>
      <table>
        <tbody>
          <tr>
            <td className="label">Partner:</td>
            <td className="data">{batch?.partner}</td>
          </tr>
          <tr>
            <td className="label">Batch ID:</td>
            <td className="data">{batch?.id}</td>
          </tr>
          <tr>
            <td className="label">Batch Number:</td>
            <td className="data">{batch?.batch}</td>
          </tr>
          <tr>
            <td className="label">Created By:</td>
            <td className="data">{batch?.created_by}</td>
          </tr>
          <tr>
            <td className="label">Shared At:</td>
            <td className="data">
              <div className="shared-at-container">
                {batch?.shared_at
                  ? format(
                      parseISO(batch.shared_at),
                      'MMM d, yyyy hh:mm aaa zzz',
                    )
                  : `Hasn't been shared`}
                {!batch?.shared_at && (
                  <Button
                    color="dark-blue outline"
                    loading={settingSharedAt}
                    onClick={setSharedAt}
                  >
                    Set Shared At
                  </Button>
                )}
              </div>
            </td>
          </tr>
          <tr>
            <td className="label">Created At:</td>
            <td className="data">
              {batch &&
                format(
                  parseISO(batch.inserted_at),
                  'MMM d, yyyy hh:mm aaa zzz',
                )}
            </td>
          </tr>
          <tr>
            <td className="label">Last Updated At:</td>
            <td className="data">
              {batch &&
                format(parseISO(batch.updated_at), 'MMM d, yyyy hh:mm aaa zzz')}
            </td>
          </tr>
          <tr>
            <td className="label">Note:</td>
            <td className="data">{batch?.note}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default BatchInfo
