import React from 'react'
import PropTypes from 'prop-types'

const AlternateLockSVG = ({ size, fill }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.7497 5.66634H12.0413V4.24967C12.0413 2.29467 10.4547 0.708008 8.49967 0.708008C6.54467 0.708008 4.95801 2.29467 4.95801 4.24967V5.66634H4.24967C3.47051 5.66634 2.83301 6.30384 2.83301 7.08301V14.1663C2.83301 14.9455 3.47051 15.583 4.24967 15.583H12.7497C13.5288 15.583 14.1663 14.9455 14.1663 14.1663V7.08301C14.1663 6.30384 13.5288 5.66634 12.7497 5.66634ZM8.49967 12.0413C7.72051 12.0413 7.08301 11.4038 7.08301 10.6247C7.08301 9.84551 7.72051 9.20801 8.49967 9.20801C9.27884 9.20801 9.91634 9.84551 9.91634 10.6247C9.91634 11.4038 9.27884 12.0413 8.49967 12.0413ZM10.6955 5.66634H6.30384V4.24967C6.30384 3.03842 7.28842 2.05384 8.49967 2.05384C9.71092 2.05384 10.6955 3.03842 10.6955 4.24967V5.66634Z"
      fill="#EB5757"
    />
  </svg>
)

AlternateLockSVG.defaultProps = {
  size: '24',
  fill: '#D5DDE4',
}

AlternateLockSVG.propTypes = {
  fill: PropTypes.string,
  size: PropTypes.string.isRequired,
}

export default AlternateLockSVG
