import React from 'react'
import PropTypes from 'prop-types'

const RemoveCircleSVG = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path fill="none" d="M0 0h24v24H0V0z" />
    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm4 11H8c-.55 0-1-.45-1-1s.45-1 1-1h8c.55 0 1 .45 1 1s-.45 1-1 1z" />
  </svg>
)

RemoveCircleSVG.defaultProps = {
  size: '24',
}

RemoveCircleSVG.propTypes = {
  size: PropTypes.string.isRequired,
}

export default RemoveCircleSVG
