import React from 'react'
import PropTypes from 'prop-types'

const DownloadSVG = ({ size }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size}>
    <path fill="none" d="M0 0h24v24H0V0z" />
    <path d="M16.59 9H15V4c0-.55-.45-1-1-1h-4c-.55 0-1 .45-1 1v5H7.41c-.89 0-1.34 1.08-.71 1.71l4.59 4.59c.39.39 1.02.39 1.41 0l4.59-4.59c.63-.63.19-1.71-.7-1.71zM5 19c0 .55.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1H6c-.55 0-1 .45-1 1z" />
  </svg>
)

DownloadSVG.defaultProps = {
  size: '24',
}

DownloadSVG.propTypes = {
  size: PropTypes.string.isRequired,
}

export default DownloadSVG
