import { NavLink } from 'react-router-dom'
import logo from 'logo.svg'
import './NavBar.scss'

function NavBar() {
  return (
    <nav className="nav-bar">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h1>Admin</h1>
      </header>
      <ul className="nav-items">
        <li className="nav-item">
          <NavLink to="/users">Users</NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/partners">Partners</NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/reports">Reports</NavLink>
        </li>
      </ul>
      <div className="logout">
        <NavLink to={'/login'}>Logout</NavLink>
      </div>
    </nav>
  )
}

export default NavBar
