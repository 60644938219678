import React from 'react'
import PropTypes from 'prop-types'

const ProgressReportIconSVG = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="2.5" cy="17.5" r="2.5" fill="#80BC5F" />
    <circle cx="2.5" cy="25" r="2.5" fill="#464647" />
    <circle cx="2.5" cy="32.5" r="2.5" fill="#B30015" />
    <circle cx="2.5" cy="40" r="2.5" fill="#B30015" />
    <circle cx="10" cy="17.5" r="2.5" fill="#80BC5F" />
    <circle cx="10" cy="25" r="2.5" fill="#464647" />
    <circle cx="10" cy="32.5" r="2.5" fill="#B30015" />
    <circle cx="10" cy="40" r="2.5" fill="#B30015" />
    <circle cx="17.5" cy="25" r="2.5" fill="#464647" />
    <circle cx="17.5" cy="32.5" r="2.5" fill="#B30015" />
    <circle cx="17.5" cy="40" r="2.5" fill="#B30015" />
    <circle cx="25" cy="10" r="2.5" fill="#80BC5F" />
    <circle cx="25" cy="17.5" r="2.5" fill="#80BC5F" />
    <circle cx="25" cy="32.5" r="2.5" fill="#B30015" />
    <circle cx="25" cy="40" r="2.5" fill="#B30015" />
    <circle cx="32.5" cy="17.5" r="2.5" fill="#80BC5F" />
    <circle cx="32.5" cy="25" r="2.5" fill="#464647" />
    <circle cx="32.5" cy="32.5" r="2.5" fill="#B30015" />
    <circle cx="40" cy="17.5" r="2.5" fill="#80BC5F" />
    <circle cx="40" cy="32.5" r="2.5" fill="#B30015" />
    <circle cx="40" cy="40" r="2.5" fill="#B30015" />
    <circle cx="47.5" cy="10" r="2.5" fill="#80BC5F" />
    <circle cx="47.5" cy="17.5" r="2.5" fill="#80BC5F" />
    <circle cx="47.5" cy="25" r="2.5" fill="#464647" />
    <circle cx="47.5" cy="32.5" r="2.5" fill="#B30015" />
    <circle cx="55" cy="10" r="2.5" fill="#80BC5F" />
    <circle cx="55" cy="17.5" r="2.5" fill="#80BC5F" />
    <circle cx="55" cy="25" r="2.5" fill="#464647" />
    <circle cx="55" cy="32.5" r="2.5" fill="#B30015" />
    <circle cx="62.5" cy="10" r="2.5" fill="#80BC5F" />
    <circle cx="62.5" cy="17.5" r="2.5" fill="#80BC5F" />
    <circle cx="62.5" cy="25" r="2.5" fill="#464647" />
    <circle cx="70" cy="10" r="2.5" fill="#80BC5F" />
    <circle cx="17.5" cy="2.5" r="2.5" fill="#80BC5F" />
    <circle cx="32.5" cy="2.5" r="2.5" fill="#80BC5F" />
    <circle cx="40" cy="2.5" r="2.5" fill="#80BC5F" />
    <circle cx="70" cy="2.5" r="2.5" fill="#80BC5F" />
    <circle cx="70" cy="17.5" r="2.5" fill="#80BC5F" />
    <circle cx="70" cy="25" r="2.5" fill="#464647" />
    <circle cx="2.5" cy="47.5" r="2.5" fill="#B30015" />
    <circle cx="10" cy="47.5" r="2.5" fill="#B30015" />
    <circle cx="32.5" cy="47.5" r="2.5" fill="#B30015" />
    <circle cx="55" cy="47.5" r="2.5" fill="#B30015" />
  </svg>
)

ProgressReportIconSVG.defaultProps = {
  width: 73,
  height: 58,
}

ProgressReportIconSVG.propTypes = {
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
}

export default ProgressReportIconSVG
