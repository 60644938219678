import React from 'react'
import PropTypes from 'prop-types'

const SubsidiaryIconSVG = ({ size, fill }) => (
  <svg
    width={size}
    height={size}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.79985 4.20015C9.79985 5.74654 8.54625 7.00015 6.99985 7.00015C5.45346 7.00015 4.19985 5.74654 4.19985 4.20015C4.19985 2.65375 5.45346 1.40015 6.99985 1.40015C8.54625 1.40015 9.79985 2.65375 9.79985 4.20015Z"
      fill="white"
    />
    <path
      d="M11.8999 9.80015C11.8999 11.3465 9.70605 12.6001 6.99985 12.6001C4.29366 12.6001 2.09985 11.3465 2.09985 9.80015C2.09985 8.25375 4.29366 7.00015 6.99985 7.00015C9.70605 7.00015 11.8999 8.25375 11.8999 9.80015Z"
      fill="white"
    />
    <path
      d="M7 0C3.136 0 0 3.136 0 7C0 10.864 3.136 14 7 14C10.864 14 14 10.864 14 7C14 3.136 10.864 0 7 0ZM7 2.1C8.162 2.1 9.1 3.038 9.1 4.2C9.1 5.362 8.162 6.3 7 6.3C5.838 6.3 4.9 5.362 4.9 4.2C4.9 3.038 5.838 2.1 7 2.1ZM7 12.04C5.25 12.04 3.703 11.144 2.8 9.786C2.821 8.393 5.6 7.63 7 7.63C8.393 7.63 11.179 8.393 11.2 9.786C10.297 11.144 8.75 12.04 7 12.04Z"
      fill={fill}
    />
  </svg>
)

SubsidiaryIconSVG.defaultProps = {
  size: '14',
  fill: '#173A56',
}

SubsidiaryIconSVG.propTypes = {
  fill: PropTypes.string,
  size: PropTypes.string.isRequired,
}

export default SubsidiaryIconSVG
