import React from 'react'
import PropTypes from 'prop-types'

const AddCircleGreenOnGrey = ({ size, className }) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <circle cx="14.0002" cy="14" r="14" fill="#F9FAFB" />
      <path
        d="M20.2487 14.3188C20.2489 14.1425 20.1789 13.9733 20.0543 13.8486C19.9296 13.7239 19.7604 13.6539 19.584 13.6541L14.9784 13.6494L14.9784 9.03908C14.9786 8.86273 14.9086 8.69355 14.7839 8.56886C14.6593 8.44416 14.4901 8.3742 14.3137 8.3744C13.946 8.3744 13.649 8.67138 13.649 9.03908L13.649 13.6494L9.03871 13.6494C8.67102 13.6494 8.37403 13.9464 8.37403 14.3141C8.37403 14.6818 8.67102 14.9788 9.03871 14.9788L13.649 14.9788L13.649 19.5891C13.649 19.9568 13.946 20.2538 14.3137 20.2538C14.6814 20.2538 14.9784 19.9568 14.9784 19.5891L14.9784 14.9788L19.5887 14.9788C19.947 14.9788 20.2487 14.6771 20.2487 14.3188Z"
        fill="#27AE60"
      />
    </g>
  </svg>
)

AddCircleGreenOnGrey.defaultProps = {
  size: '28',
}

AddCircleGreenOnGrey.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string.isRequired,
}

export default AddCircleGreenOnGrey
