import React from 'react'
import PropTypes from 'prop-types'

const MyCompanySVG = ({ width, height, fillOutside, fillInside }) => (
  // default width="58" height="48"
  <svg width={width} height={height} fill="none" className="my-company-svg">
    <path
      d="M13.0672 0H1.6334C0.735031 0 0.00816701 0.735031 0.00816701 1.6334L0 12.1933C0 12.7569 0.285845 13.2551 0.718697 13.5491L6.90112 17.6652C7.1788 17.8449 7.52999 17.8449 7.80766 17.6652L13.9819 13.5491C14.4148 13.2551 14.7006 12.7569 14.7006 12.1933V1.6334C14.7006 0.735031 13.9656 0 13.0672 0V0Z"
      fill={fillInside}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.6334 0H13.0672C13.9656 0 14.7006 0.735031 14.7006 1.6334V12.1933C14.7006 12.7569 14.4148 13.2551 13.9819 13.5491L7.80766 17.6652C7.52998 17.8449 7.1788 17.8449 6.90112 17.6652L0.718697 13.5491C0.285845 13.2551 0 12.7569 0 12.1933L0.00816701 1.6334C0.00816701 0.735031 0.735031 0 1.6334 0ZM6.12359 8.69236V11.755C6.12359 12.0919 5.84795 12.3675 5.51107 12.3675H3.67349C3.3366 12.3675 3.06096 12.0919 3.06096 11.755V7.46731H2.01967C1.73791 7.46731 1.60928 7.11817 1.81754 6.93441L6.93825 2.32209C7.17101 2.11383 7.52628 2.11383 7.75904 2.32209L12.8798 6.93441C13.0941 7.11817 12.9594 7.46731 12.6776 7.46731H11.6363V11.755C11.6363 12.0919 11.3607 12.3675 11.0238 12.3675H9.18622C8.84933 12.3675 8.57369 12.0919 8.57369 11.755V8.69236H6.12359Z"
      fill={fillOutside}
    />
  </svg>
)

MyCompanySVG.defaultProps = {
  width: '15',
  height: '18',
  fillOutside: '#2F80ED',
  fillInside: '#FFF',
}

MyCompanySVG.propTypes = {
  fillInside: PropTypes.string.isRequired,
  fillOutside: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
}

export default MyCompanySVG
