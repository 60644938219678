import React from 'react'
import PropTypes from 'prop-types'

const StrikeXSVG = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.2499 0.757931C11.0942 0.601887 10.8828 0.514193 10.6624 0.514193C10.4419 0.514193 10.2305 0.601887 10.0749 0.757931L5.99985 4.8246L1.92485 0.749597C1.76916 0.593553 1.55778 0.505859 1.33735 0.505859C1.11692 0.505859 0.905546 0.593553 0.749853 0.749597C0.424854 1.0746 0.424854 1.5996 0.749853 1.9246L4.82485 5.9996L0.749853 10.0746C0.424854 10.3996 0.424854 10.9246 0.749853 11.2496C1.07485 11.5746 1.59985 11.5746 1.92485 11.2496L5.99985 7.1746L10.0749 11.2496C10.3999 11.5746 10.9249 11.5746 11.2499 11.2496C11.5749 10.9246 11.5749 10.3996 11.2499 10.0746L7.17485 5.9996L11.2499 1.9246C11.5665 1.60793 11.5665 1.0746 11.2499 0.757931Z"
      fill="#173A56"
    />
  </svg>
)

StrikeXSVG.defaultProps = {
  size: '12',
}

StrikeXSVG.propTypes = {
  size: PropTypes.string.isRequired,
}

export default StrikeXSVG
