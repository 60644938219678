import { useToastAlert } from 'components/ToastAlert'
import { useEffect, useState, useRef } from 'react'
import { Link, useParams } from 'react-router-dom'
import axios, { AxiosRequestConfig } from 'axios'
import { format, parseISO } from 'date-fns'
import {
  Table,
  TableBody,
  TableHead,
  TableCellHeader,
  TableRow,
  TableCellData,
  Pagination,
  Button,
} from 'components/common'
import type { TableConfig } from '../../Partners'
import '../../UsersTable.scss'
import './AccessCodes.scss'

export interface AccessCode {
  id: number
  code: string
  batch_id: number
  created_by: string
  used_by: number
  used_at: string
  expires_at: string
  inserted_at: string
  updated_at: string
}

const tableHeaders = [
  { label: 'ID', id: 'id' },
  { label: 'Code', id: 'code' },
  { label: 'Batch ID', id: 'batch_id' },
  { label: 'Created By', id: 'created_by' },
  { label: 'Used By', id: 'used_by' },
  { label: 'Used At', id: 'used_at' },
  { label: 'Expires At', id: 'expires_at' },
  { label: 'Inserted At', id: 'inserted_at' },
  { label: 'Updated At', id: 'updated_at' },
]

function AccessCodes() {
  // const history = useHistory()
  const { batch_id, partner_id } = useParams<{
    batch_id?: string
    partner_id?: string
  }>()
  const tableContainerEl = useRef<HTMLDivElement>(null)
  const { setRequestErrorToast } = useToastAlert()
  const [loaded, setLoaded] = useState<boolean>(false)
  const [downloading, setDownloading] = useState<boolean>(false)
  const [accessCodes, setAccessCodes] = useState<AccessCode[]>([])
  const [totalCount, setTotalCount] = useState<number | null>(null)
  const [tableConfig, setTableConfig] = useState<TableConfig>({
    method: 'asc_nulls_last',
    field: 'used_at',
    activePage: 1,
    totalPages: 1,
  })

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        if (tableContainerEl !== null && tableContainerEl.current !== null) {
          tableContainerEl.current.scrollTo(0, 0)
        }
        setLoaded(false)
        const requestOptions: AxiosRequestConfig = {
          method: 'get',
          baseURL: process.env.REACT_APP_AUTH_URL,
          url: `/mcr/admin/batch/${batch_id}/access-codes`,
          params: {
            method: tableConfig.method,
            field: tableConfig.field,
            page: tableConfig.activePage,
            perPage: 100,
          },
        }
        const { data } = await axios(requestOptions)
        setAccessCodes(data.results)
        setTotalCount(data.count)
        setTableConfig((prevTableConfig) => ({
          ...prevTableConfig,
          totalPages: data.page_count,
        }))
        setLoaded(true)
      } catch (e) {
        setRequestErrorToast('There was a problem fetching the partners.')
        setLoaded(true)
        console.log(e)
      }
    }
    fetchUsers()
  }, [
    tableConfig.method,
    tableConfig.field,
    tableConfig.activePage,
    batch_id,
    setLoaded,
    setAccessCodes,
    setRequestErrorToast,
  ])

  const changeSort = (id: string): void => {
    if (id === tableConfig.field) {
      setTableConfig((prevTableConfig) =>
        prevTableConfig.method === 'asc_nulls_last'
          ? { ...prevTableConfig, method: 'desc_nulls_last' }
          : { ...prevTableConfig, method: 'asc_nulls_last' },
      )
    } else {
      setTableConfig((prevTableConfig) => ({
        ...prevTableConfig,
        method: 'asc_nulls_last',
        field: id,
      }))
    }
  }

  const changePage = (
    e: React.MouseEvent<HTMLButtonElement>,
    { activePage }: { activePage: number },
  ): void => {
    setTableConfig((prevTableConfig) => ({
      ...prevTableConfig,
      activePage: activePage,
    }))
  }

  const downloadCSV = async () => {
    try {
      setDownloading(true)
      const requestOptions: AxiosRequestConfig = {
        method: 'get',
        baseURL: process.env.REACT_APP_AUTH_URL,
        url: `/mcr/admin/batch/${batch_id}/access-codes-csv`,
      }
      const { data } = await axios(requestOptions)

      const file = new Blob([data], { type: 'text/csv' })

      const element = document.createElement('a')
      element.href = URL.createObjectURL(file)
      element.download = `codes_${batch_id}.csv`
      document.body.appendChild(element)
      element.click()

      setDownloading(false)
    } catch (e) {
      setRequestErrorToast('There was a problem fetching the partners.')
      setDownloading(false)
      console.log(e)
    }
  }

  return (
    <div className="users-table-container">
      <div className="access-codes-header-container">
        <h2>Access Codes</h2>
        {!!accessCodes.length && (
          <Button
            color="blue outline"
            loading={downloading}
            onClick={downloadCSV}
          >
            Download CSV
          </Button>
        )}
      </div>
      {loaded ? (
        accessCodes.length ? (
          <>
            <div>
              <table className="batch-count-details">
                <tbody>
                  <tr>
                    <td className="label">Code Count:</td>
                    <td className="data">
                      {new Intl.NumberFormat().format(Number(totalCount))}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="table-container" ref={tableContainerEl}>
              <Table>
                <TableHead>
                  <TableRow>
                    {tableHeaders.map(({ label, id }) => (
                      <TableCellHeader
                        key={id}
                        sortable
                        sorted={id === tableConfig.field}
                        sortDirection={tableConfig.method}
                        onClick={() => changeSort(id)}
                      >
                        {label}
                      </TableCellHeader>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {accessCodes.map((b) => {
                    return (
                      <TableRow key={b.id}>
                        <TableCellData>{b.id}</TableCellData>
                        <TableCellData>{b.code}</TableCellData>
                        <TableCellData>{b.batch_id}</TableCellData>
                        <TableCellData>{b.created_by}</TableCellData>
                        <TableCellData>
                          {b.used_by ? (
                            <Link to={`/users/${b.used_by}`}>{b.used_by}</Link>
                          ) : (
                            'unused'
                          )}
                        </TableCellData>
                        <TableCellData>
                          {b.used_at
                            ? format(
                                parseISO(b.used_at),
                                'MMM d, yyyy hh:mm aaa zzz',
                              )
                            : 'unused'}
                        </TableCellData>
                        <TableCellData>
                          {b.expires_at
                            ? format(
                                parseISO(b.expires_at),
                                'MMM d, yyyy hh:mm aaa zzz',
                              )
                            : 'never'}
                        </TableCellData>
                        <TableCellData>
                          {format(
                            parseISO(b.inserted_at),
                            'MMM d, yyyy hh:mm aaa zzz',
                          )}
                        </TableCellData>
                        <TableCellData>
                          {format(
                            parseISO(b.updated_at),
                            'MMM d, yyyy hh:mm aaa zzz',
                          )}
                        </TableCellData>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </div>
            <div className="table-controls">
              <Pagination
                activePage={tableConfig.activePage}
                onPageChange={changePage}
                totalPages={tableConfig.totalPages}
              />
            </div>
          </>
        ) : (
          <div className="add-access-codes">
            <Link
              to={`/partners/${partner_id}/batch/${batch_id}/add-access-codes`}
            >
              <Button>Add Access Codes</Button>
            </Link>
          </div>
        )
      ) : (
        <div>Loading...</div>
      )}
    </div>
  )
}

export default AccessCodes
