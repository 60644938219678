import React from 'react'
import PropTypes from 'prop-types'

const SubsidiaryIconSmallSVG = ({ fill }) => (
  <svg
    className="subsidiary-icon"
    width="11px"
    height="11px"
    viewBox="0 0 11 11"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g>
        <circle
          id="Oval"
          fill="#FFFFFF"
          fillRule="nonzero"
          cx="5.5"
          cy="5.5"
          r="5.5"
        ></circle>
        <path
          d="M5.50003385,0 C2.46401692,0 0,2.46401692 0,5.50003385 C0,8.53608462 2.46401692,11.0000846 5.50003385,11.0000846 C8.53608462,11.0000846 11.0000846,8.53608462 11.0000846,5.50003385 C11.0000846,2.46401692 8.53608462,0 5.50003385,0 Z M5.50000846,1.64994923 C6.41300846,1.64994923 7.15001692,2.38694923 7.15001692,3.29995769 C7.15001692,4.21295769 6.41300846,4.94996615 5.50000846,4.94996615 C4.587,4.94996615 3.84999154,4.21295769 3.84999154,3.29995769 C3.84999154,2.38694923 4.587,1.64994923 5.50000846,1.64994923 Z M2.19999154,7.68893231 C2.90949154,8.75591538 4.125,9.45991538 5.50000846,9.45991538 C6.87501692,9.45991538 8.09052538,8.75591538 8.8,7.68893231 C8.7835,6.59442385 6.59451692,5.99491538 5.50000846,5.99491538 C4.4,5.99491538 2.21649154,6.59442385 2.19999154,7.68893231 Z"
          id="Shape"
          fill={fill}
        ></path>
      </g>
    </g>
  </svg>
)

SubsidiaryIconSmallSVG.defaultProps = {
  fill: '#173A56',
}

SubsidiaryIconSmallSVG.propTypes = {
  fill: PropTypes.string,
}

export default SubsidiaryIconSmallSVG
