import React from 'react'
import PropTypes from 'prop-types'

const EllipsisDark = ({ size, className }) => (
  <svg
    className={className}
    width={size * 18}
    height={size * 6}
    viewBox="0 0 18 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.49967 0.833008C1.30801 0.833008 0.333008 1.80801 0.333008 2.99967C0.333008 4.19134 1.30801 5.16634 2.49967 5.16634C3.69134 5.16634 4.66634 4.19134 4.66634 2.99967C4.66634 1.80801 3.69134 0.833008 2.49967 0.833008ZM15.4997 0.833008C14.308 0.833008 13.333 1.80801 13.333 2.99967C13.333 4.19134 14.308 5.16634 15.4997 5.16634C16.6913 5.16634 17.6663 4.19134 17.6663 2.99967C17.6663 1.80801 16.6913 0.833008 15.4997 0.833008ZM6.83301 2.99967C6.83301 1.80801 7.80801 0.833008 8.99967 0.833008C10.1913 0.833008 11.1663 1.80801 11.1663 2.99967C11.1663 4.19134 10.1913 5.16634 8.99967 5.16634C7.80801 5.16634 6.83301 4.19134 6.83301 2.99967Z"
      fill="#173A56"
    />
  </svg>
)

EllipsisDark.defaultProps = {
  size: '1',
}

EllipsisDark.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string.isRequired,
}

export default EllipsisDark
