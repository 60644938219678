import React from 'react'
import classnames from 'classnames'
import { LoadingSVG } from 'assets/svg'
import './Button.scss'

interface ButtonProps {
  children: React.ReactNode
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  type?: 'button' | 'submit' | 'reset'
  disabled?: boolean
  color?:
    | 'green'
    | 'green outline'
    | 'red'
    | 'red outline'
    | 'blue'
    | 'blue outline'
    | 'dark-blue'
    | 'dark-blue outline'
    | 'dark'
    | 'dark outline'
  className?: string
  loading?: boolean
}

/**
 * Use `Button` as primary button element.
 */
const Button = ({
  children,
  onClick,
  type = 'button',
  disabled = false,
  color = 'green',
  className,
  loading = false,
  ...rest
}: ButtonProps) => {
  return (
    <button
      type={type}
      onClick={loading ? undefined : onClick}
      disabled={disabled}
      className={classnames('v3-button', color, className, { loading })}
      {...rest}
    >
      {loading ? <LoadingSVG size="24" /> : children}
    </button>
  )
}

export default Button
