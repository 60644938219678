import React from 'react'
import PropTypes from 'prop-types'

const ProgressReportGraphIconSVG = ({ size, fill }) => (
  <svg
    width={size}
    height={size}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="2" cy="12" r="2" fill={fill} />
    <circle cx="7" cy="12" r="2" fill={fill} />
    <circle cx="12" cy="12" r="2" fill={fill} />
    <circle cx="17" cy="12" r="2" fill={fill} />
    <circle cx="22" cy="12" r="2" fill={fill} />
    <circle cx="2" cy="17" r="2" fill={fill} />
    <circle cx="7" cy="17" r="2" fill={fill} />
    <circle cx="12" cy="17" r="2" fill={fill} />
    <circle cx="17" cy="17" r="2" fill={fill} />
    <circle cx="7" cy="22" r="2" fill={fill} />
    <circle cx="12" cy="7" r="2" fill={fill} />
    <circle cx="17" cy="7" r="2" fill={fill} />
    <circle cx="22" cy="7" r="2" fill={fill} />
    <circle cx="17" cy="2" r="2" fill={fill} />
  </svg>
)

ProgressReportGraphIconSVG.defaultProps = {
  fill: '#173A56',
  size: '24',
}

ProgressReportGraphIconSVG.propTypes = {
  fill: PropTypes.string,
  size: PropTypes.string.isRequired,
}

export default ProgressReportGraphIconSVG
