import { useState } from 'react'
import axios, { AxiosRequestConfig } from 'axios'
import classnames from 'classnames'
import {
  Loading,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCellHeader,
  TableBody,
  TableCellData,
} from 'components/common'
import map from 'ramda/src/map'
import sortBy from 'ramda/src/sortBy'
import prop from 'ramda/src/prop'
import pipe from 'ramda/src/pipe'
import { CTAR } from './AssociatedToes'
import { useToastAlert } from 'components/ToastAlert'
import './AssociatedToesTable.scss'

interface AssocatedToesTableProps {
  loaded: boolean
  setCtars: React.Dispatch<React.SetStateAction<CTAR[]>>
  ctars: CTAR[]
}

function AssociatedToesTable({
  loaded,
  setCtars,
  ctars,
}: AssocatedToesTableProps) {
  const [updatingId, setUpdatingId] = useState<number | null>(null)
  const { setRequestErrorToast } = useToastAlert()

  const updateToeAssociation = async (id: number, is_deleted: boolean) => {
    try {
      setUpdatingId(id)
      const requestOptions: AxiosRequestConfig = {
        method: 'put',
        baseURL: process.env.REACT_APP_AUTH_URL,
        url: '/mcr/admin/update-toe-association',
        data: {
          id,
          is_deleted,
        },
      }
      await axios(requestOptions)
      setCtars((ctars) =>
        ctars.map((ctar) => (ctar.id === id ? { ...ctar, is_deleted } : ctar)),
      )
      setUpdatingId(null)
    } catch (e) {
      setRequestErrorToast('There was a problem updating the toe association.')
      console.log(e)
    }
  }

  if (loaded && ctars.length === 0)
    return (
      <div className="no-toe">
        There are no associated toes with this customer.
      </div>
    )

  return (
    <div className="associated-toe">
      <div className="ctars-container">
        <Table>
          <TableHead>
            <TableRow>
              <TableCellHeader>Toe Name</TableCellHeader>
              <TableCellHeader>CTAR Id</TableCellHeader>
              <TableCellHeader>Date Created</TableCellHeader>
              <TableCellHeader>Active?</TableCellHeader>
              <TableCellHeader></TableCellHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {pipe(
              (ctars: CTAR[]) => sortBy(prop('is_deleted'))(ctars),
              map((ctar: CTAR) => {
                const is_deleted = ctar.is_deleted
                return (
                  <TableRow
                    className={classnames('ctar-item', {
                      inactive: is_deleted,
                    })}
                    key={ctar.id}
                  >
                    <TableCellData className="toe-item">
                      {ctar.toe_short_name}
                    </TableCellData>
                    <TableCellData className="toe-item">
                      {ctar.id}
                    </TableCellData>
                    <TableCellData className="toe-item">
                      {ctar.date_created}
                    </TableCellData>
                    <TableCellData
                      className={classnames('active', { is_deleted })}
                    >
                      {is_deleted ? 'False' : 'True'}
                    </TableCellData>
                    <TableCellData className="button">
                      <Button
                        color={is_deleted ? 'green outline' : 'red outline'}
                        onClick={() =>
                          updateToeAssociation(
                            ctar.id,
                            is_deleted ? false : true,
                          )
                        }
                        loading={updatingId === ctar.id}
                      >
                        {is_deleted ? 'Activate' : 'Deactivate'}
                      </Button>
                    </TableCellData>
                  </TableRow>
                )
              }),
            )(ctars)}
          </TableBody>
        </Table>
        <Loading overlay size="small" isLoading={!loaded} />
      </div>
    </div>
  )
}

export default AssociatedToesTable
