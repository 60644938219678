import React from 'react'
import PropTypes from 'prop-types'

const PortfolioRatingsIconSVG = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    fill="none"
    viewBox={`0 0 ${width} ${height}`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 2h40M15 8h40M15 18h40M15 24h40M15 34h40M15 40h40"
      stroke="#D5DDE4"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      className="no-stroke"
      d="M63 2a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1h-6a1 1 0 01-1-1V2z"
      fill="#2F80ED"
    />
    <path
      className="no-stroke"
      d="M63 18a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1h-6a1 1 0 01-1-1v-6z"
      fill="#F2C94C"
    />
    <path
      className="no-stroke"
      d="M63 34a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1h-6a1 1 0 01-1-1v-6z"
      fill="#B30015"
    />
    <path
      className="no-stroke"
      d="M84.875 4.35a.75.75 0 010 1.3l-5.25 3.03a.75.75 0 01-1.125-.649V1.97a.75.75 0 011.125-.65l5.25 3.031z"
      fill="#D5DDE4"
    />
    <path
      className="no-stroke"
      d="M81.65 23.875a.75.75 0 01-1.3 0l-3.03-5.25a.75.75 0 01.649-1.125h6.062a.75.75 0 01.65 1.125l-3.031 5.25z"
      fill="#B30015"
    />
    <path
      className="no-stroke"
      d="M81.65 34.125a.75.75 0 00-1.3 0l-3.03 5.25a.75.75 0 00.649 1.125h6.062a.75.75 0 00.65-1.125l-3.031-5.25z"
      fill="#27AE60"
    />
    <circle cx="4" cy="5" r="4" fill="#173A56" />
    <circle cx="4" cy="21" r="4" fill="#173A56" />
    <circle cx="4" cy="37" r="4" fill="#173A56" />
  </svg>
)

PortfolioRatingsIconSVG.defaultProps = {
  width: 86,
  height: 43,
}

PortfolioRatingsIconSVG.propTypes = {
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
}

export default PortfolioRatingsIconSVG
