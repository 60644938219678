import axios, { AxiosRequestConfig } from 'axios'
import { useToastAlert } from 'components/ToastAlert'
import { Button, TextAreaInput, TextInputType } from 'components/common'
import { useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import './CreateBatch.scss'
import { ChevronRightSVG } from 'assets/svg'

function CreateBatch() {
  const history = useHistory()
  const { partner_id } = useParams<{ partner_id?: string }>()
  const [loading, setLoading] = useState(false)
  const [note, setNote] = useState('')
  const { setRequestErrorToast } = useToastAlert()

  const handleSubmit = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault()

    const requestOptions: AxiosRequestConfig = {
      method: 'post',
      baseURL: process.env.REACT_APP_AUTH_URL,
      url: `/mcr/admin/partner/${partner_id}/create-batch`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: { note },
    }

    try {
      setLoading(true)
      await axios(requestOptions)
      setLoading(false)
      history.push(`/partners/${partner_id}`)
    } catch (e) {
      setRequestErrorToast('An error was encountered while creating the batch.')
      setLoading(false)
    }
  }

  return (
    <div id="partner-details-container">
      <div className="header-container">
        <Link to={`/partners/${partner_id}`} className="back">
          <ChevronRightSVG size="24" />
          Back
        </Link>
        <h1>Create a Batch</h1>
      </div>
      <form className="create-batch-form" onSubmit={handleSubmit}>
        <TextAreaInput
          id="note"
          value={note}
          label="Note"
          type={TextInputType.EmailType}
          placeholder="some batch note..."
          onChange={({ target: { value } }: { target: { value: string } }) =>
            setNote(value)
          }
          fullBorder
        />
        <Button type="submit" loading={loading}>
          Submit
        </Button>
      </form>
    </div>
  )
}

export default CreateBatch
