import { Switch, Route } from 'react-router-dom'
import AdminDashboard from 'components/AdminDashboard/AdminDashboard'
import Login from 'components/Login'
import ToastAlert from 'components/ToastAlert'
import './App.scss'

function App() {
  return (
    <ToastAlert>
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route path="/" component={AdminDashboard} />
      </Switch>
    </ToastAlert>
  )
}

export default App
