import React from 'react'
import PropTypes from 'prop-types'

const EnterpriseBenchmarkSVG = ({ width, height }) => (
  // default width="58" height="48"
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M28.347 1.116V46.88" stroke="#0E2435" strokeWidth="2" />
    <path
      d="M3.79 9.488h24.557c2.046 0 6.809.893 9.488 4.465 3.348 4.464 10.045 10.045 15.068 10.045"
      stroke="#435A70"
      strokeWidth="2"
    />
    <path
      d="M3.79 23.998c4.465 0 8.93 3.349 12.279 7.256 3.348 3.906 7.255 7.255 12.278 7.255h24.556"
      stroke="#B5CDE3"
      strokeWidth="2"
    />
    <path
      d="M3.79 46.88h49.113M3.232 1.116h51.346M4.349 0v47.997M53.461 0v47.997"
      stroke="#0E2435"
      strokeWidth="2"
    />
    <circle cx="53.113" cy="38.718" r="4" fill="#B5CDE3" />
    <circle cx="53.113" cy="24.208" r="4" fill="#435A70" />
    <circle cx="53.113" cy="9.697" r="4" fill="#2F80ED" />
    <circle cx="28.557" cy="24.208" r="4" fill="#2F80ED" />
    <circle cx="28.557" cy="38.718" r="4" fill="#B5CDE3" />
    <circle cx="28.557" cy="9.697" r="4" fill="#435A70" />
    <circle cx="4" cy="38.718" r="4" fill="#2F80ED" />
    <circle cx="4" cy="24.208" r="4" fill="#B5CDE3" />
    <circle cx="4" cy="9.697" r="4" fill="#435A70" />
    <path
      d="M3.79 38.509c1.86-.186 6.363-1.34 9.488-4.465 3.907-3.907 8.372-10.046 15.069-10.046 6.697 0 15.069-10.046 17.301-12.278 2.232-2.232 5.023-2.232 7.255-2.232"
      stroke="#2F80ED"
      strokeWidth="2"
    />
  </svg>
)

EnterpriseBenchmarkSVG.defaultProps = {
  width: 58,
  height: 48,
}

EnterpriseBenchmarkSVG.propTypes = {
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
}

export default EnterpriseBenchmarkSVG
